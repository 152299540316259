import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Panno from '../components/Panno'
function Panneaux() {
    return (
        <>
            <header>
                <Header />
            </header>
            <main>
                <section>
                    <Panno />
                </section>
            </main>
            <footer>
                <Footer />
            </footer>

        </>
    )
}

export default Panneaux