import { Alert, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material'
import React from 'react'
import contact from '../assets/31_zxyh_fotolia_86228135_l.-famille-dans-maison.jpg';
import question from '../assets/faq-685060_640.webp';
import downImg from '../assets/contact.png';
import { Typewriter } from 'react-simple-typewriter';
import emailjs from 'emailjs-com';
function ContactForm() {
    const [open, setOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('success');

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [type, setType] = React.useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        // Make fields required
        if (!name || !email || !phone || !message || !type) {
            setAlertMessage('Veuillez remplir tous les champs.');
            setAlertSeverity('error');
            setOpen(true);
            return;
        }

        const templateParams = {
            name: name,
            message: message,
            email: email,
            phone: phone,
            type: type,
        };

        emailjs.send('service_27k6s9b', 'template_pjamw1l', templateParams, "s6Nh9NJ9gJmcA1AL9")
            .then((response) => {
                console.log('Données envoyées avec succès!', response.status, response.text);
                setAlertMessage('Votre message à été envoyé.');
                setAlertSeverity('success');
                setOpen(true);
                // Clear the form
                setName('');
                setMessage('');
                setEmail('');
                setPhone('');

                setType('');
            }, (err) => {
                console.error('Erreur lors de l\'envoi des données : ', err);
                setAlertMessage('Erreur lors de l\'envoi du message.');
                setAlertSeverity('error');
                setOpen(true);
            });
    };
    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%', fontSize: '1.5em' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Box sx={{ position: 'relative' }}>
                <Box sx={{ display: { xs: 'none', md: 'block' }, marginBottom: '20px', position: 'absolute', top: -55, left: 0, right: 0, width: '15%', backgroundColor: '#053b63', borderRadius: '0 40px 40px 0', color: 'white', zIndex: 10, padding: '20px', textAlign: 'center' }}>
                    <Typography letterSpacing={"2px"} variant='h6'>Nous contacter</Typography>
                </Box>
                <Box sx={{ maxWidth: '100%', margin: 'auto', marginTop: { xs: '50px', md: '170px' }, marginBottom: '150px' }}>
                    <Grid container alignItems={'center'} spacing={0}>

                        <Grid item xs={12} md={6}>
                            <Box sx={{ padding: '25px', textAlign: 'center', p: 6 }}>
                                <Typography sx={{ color: '#053961', fontWeight: 'bold', }} variant="h4">Besoin d’aide pour votre projet de rénovation énergétique ?
                                    <br /> <span style={{ color: '#e43645', fontWeight: 'bold' }}> <Typewriter
                                        words={['Contactez-nous']}
                                        typeSpeed={50}
                                        cursorBlinking={true}
                                        cursorColor="#e43645"
                                        cursor={true}
                                        cursorStyle="|"
                                    /></span></Typography>
                                <Typography sx={{ color: '#053961', fontWeight: 'light', fontSize: '18px' }} variant="h6" style={{ marginTop: "15px" }}>Vous avez besoin d’être accompagné ? Vous hésitez entre divers projets ?  Ou alors vous avez tout simplement besoin d’un renseignement précis sur un point concernant l’Agence ou le domaine des énergies renouvelables ?
                                    Vous êtes au bon endroit !
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box component='img' width='80%' borderRadius={'30px'} height='auto' src={contact} alt='france' />
                        </Grid>

                    </Grid>
                </Box>
                <Box sx={{ maxWidth: '100%', margin: 'auto', marginTop: '150px', marginBottom: '150px' }}>
                    <Grid container alignItems={'center'} spacing={0}>

                        <Grid display={'flex'} alignItems={'center'} flexDirection={'column'} item xs={12} md={6}>
                            <Box component={'img'} width='30%' textAlign={'center'} src={question} alt='france' />
                            <Box sx={{ padding: '25px', textAlign: 'center', p: 6 }}>
                                <Typography sx={{ color: '#053961', fontWeight: 'bold' }} variant="h4">UNE QUESTION ?

                                    <br /> <span style={{ color: '#e43645', fontWeight: 'bold' }}>Envoyez-nous un message !
                                    </span></Typography>

                                <Typography sx={{ color: '#053961', fontSize: { xs: '18px' }, fontWeight: 'light' }} variant="h6" style={{ marginTop: "15px" }}>Nos conseiller spécialisés sont à votre disposition 7/7 pour répondre à vos interrogations et vous fournir les informations correspondantes.
                                    Profitez de notre formulaire de contact ci-après pour nous faire part de votre demande.
                                    Nous nous efforçons de vous répondre dans les meilleurs délais
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box className='animate__animated animate__fadeIn'
                                sx={{
                                    padding: '50px',
                                    backgroundColor: '#053961',
                                    marginBottom: '50px',
                                    display: 'flex',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    borderRadius: '30px',
                                    width: '80%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <Typography sx={{ color: '#ffffff', fontWeight: 'bold' }} variant="h5" mb={3}>Poser votre question !</Typography>

                                <form onSubmit={handleSubmit} style={{ display: 'flex', width: '100%', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>
                                    <FormControl className='animate__animated animate__fadeInRight' variant="filled" style={{ backgroundColor: '#ffffff', width: '100%', marginRight: '10px', borderRadius: '10px' }}>
                                        <InputLabel color="secondary">Type de votre question</InputLabel>
                                        <Select required defaultValue='' value={type} onChange={(event) => setType(event.target.value)}>
                                            <MenuItem value={"Equipement et installation"}>Equipement et installation</MenuItem>
                                            <MenuItem value={"SAV"}>SAV</MenuItem>
                                            <MenuItem value={"Service Client"}>Service Client</MenuItem>
                                            <MenuItem value={"Service financier"}>Service financier</MenuItem>
                                            <MenuItem value={"Autre"}>Autre</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <TextField required className='animate__animated animate__fadeInLeft' value={name}
                                        onChange={(event) => setName(event.target.value)} label="Nom/Prénom" variant="filled" style={{ backgroundColor: '#ffffff', width: '100%', marginRight: '10px', borderRadius: '10px' }} />
                                    <TextField required className='animate__animated animate__fadeInLeft' value={email}
                                        onChange={(event) => setEmail(event.target.value)} label="Email" variant="filled" style={{ backgroundColor: '#ffffff', width: '100%', marginRight: '10px', borderRadius: '10px' }} />
                                    <TextField required className='animate__animated animate__fadeInLeft' value={phone}
                                        onChange={(event) => setPhone(event.target.value)} label="Téléphone" variant="filled" style={{ backgroundColor: '#ffffff', width: '100%', marginRight: '10px', borderRadius: '10px' }} />
                                    <TextField required className='animate__animated animate__fadeInRight' value={message}
                                        onChange={(event) => setMessage(event.target.value)} label="Votre question" multiline rows={4} variant="filled" style={{ backgroundColor: '#ffffff', width: '100%', marginRight: '10px', borderRadius: '10px' }} />

                                    <Button type='submit' className='animate__animated animate__fadeInRight' variant="contained" sx={{ backgroundColor: '#609ff2', width: '100%', marginTop: '10px', maxWidth: '100px' }}>Envoyer</Button>
                                </form>
                            </Box>

                        </Grid>

                    </Grid>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: { xs: '-150px', md: '0px' } }}>

                    <Box mb={10} component={'img'} objectFit='contain' width='95%' borderRadius={'30px'} src={downImg} alt='france' />
                </Box>
            </Box>
        </>
    )
}

export default ContactForm