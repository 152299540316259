import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './style/index.scss';
import 'animate.css';
import Home from './pages/Home';
import Faq from './pages/Faq';
import Contact from './pages/Contact';
import React from 'react';
import PacAirEau from './pages/PacAirEau';
import PacAirAir from './pages/PacAirAir';
import Eligibilite from './pages/Eligibilite';
import Ballonthermo from './pages/Ballonthermo';
import Nous from './pages/Nous';
import Panneaux from './pages/Panneaux';
import ScrollToTop from './components/ScrollToTop';
function App() {
  return (
    <>

      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/ballon-thermodynamique" element={<Ballonthermo />} />
          <Route path="/panneau-photovoltaique" element={<Panneaux />} />
          <Route path="/eligibilite" element={<Eligibilite />} />
          <Route path="/pac-air-air" element={<PacAirAir />} />
          <Route path="/qui-sommes-nous" element={<Nous />} />
          <Route path="/pac-air-eau" element={<PacAirEau />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
