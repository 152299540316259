import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PACeau from '../components/PACeau'
function PacAirEau() {
    return (
        <>
            <header>
                <Header />
            </header>
            <main>
                <section>
                    <PACeau />
                </section>
            </main>
            <footer>
                <Footer />
            </footer>

        </>
    )
}

export default PacAirEau