import { Alert, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material'
import React from 'react'
import faq from '../assets/faq-685060_640.webp';
import support from '../assets/banniere-teleconseiller2jpg305405cropped.jpg';
import chaudiere from '../assets/6db4976b53c1-Atlantic_ECS_Ambiance_Egeo_garage.png';
import pompe from '../assets/813810_242103.webp';
import aidepompe from '../assets/202991_380591.webp';
import financepome from '../assets/2956401926.jpg';
import pqpompe from '../assets/581697_580181.webp';
import primepompe from '../assets/658675_366586.webp';
import tarif from '../assets/283914_426912.webp';
import question from '../assets/faq-685060_640.webp';
import { Typewriter } from 'react-simple-typewriter';
import emailjs from 'emailjs-com';
function Question() {
    const [open, setOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('success');

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [type, setType] = React.useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        // Make fields required
        if (!name || !email || !phone || !message || !type) {
            setAlertMessage('Veuillez remplir tous les champs.');
            setAlertSeverity('error');
            setOpen(true);
            return;
        }

        const templateParams = {
            name: name,
            message: message,
            email: email,
            phone: phone,
            type: type,
        };

        emailjs.send('service_27k6s9b', 'template_pjamw1l', templateParams, "s6Nh9NJ9gJmcA1AL9")
            .then((response) => {
                console.log('Données envoyées avec succès!', response.status, response.text);
                setAlertMessage('Votre message à été envoyé.');
                setAlertSeverity('success');
                setOpen(true);
                // Clear the form
                setName('');
                setMessage('');
                setEmail('');
                setPhone('');

                setType('');
            }, (err) => {
                console.error('Erreur lors de l\'envoi des données : ', err);
                setAlertMessage('Erreur lors de l\'envoi du message.');
                setAlertSeverity('error');
                setOpen(true);
            });
    };
    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%', fontSize: '1.5em' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Box sx={{ position: 'relative', padding: { xs: '5px', md: '0px' } }}>
                <Box sx={{ display: { xs: 'none', md: 'block' }, marginBottom: '20px', position: 'absolute', top: -55, left: 0, right: 0, width: '12%', backgroundColor: '#053b63', borderRadius: '0 40px 40px 0', color: 'white', zIndex: 10, padding: '20px', textAlign: 'center' }}>
                    <Typography letterSpacing={"2px"} variant='h6'>FAQ</Typography>
                </Box>
                <Box sx={{ maxWidth: '100%', margin: 'auto', marginTop: { xs: '90px', md: '170px' }, marginBottom: '150px' }}>
                    <Grid container alignItems={'center'} spacing={0}>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box component='img' width='40%' height='auto' src={faq} alt='france' />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ padding: '25px', textAlign: 'center', p: 6 }}>
                                <Typography sx={{ color: '#053961', fontWeight: 'bold' }} variant="h4">
                                    Foire aux questions
                                    <br /> <span style={{ color: '#e43645', fontWeight: 'bold' }}> <Typewriter
                                        words={['Réponses des spécialistes à vos interrogations']}
                                        typeSpeed={80}
                                        cursorBlinking={true}
                                        cursorColor="#e43645"
                                        cursor={true}
                                        cursorStyle="|"
                                    />

                                    </span></Typography>
                                <Typography sx={{ color: '#053961', fontWeight: 'light', fontSize: { xs: '18px', md: 'inherit' } }} variant="h6" style={{ marginTop: "15px" }}>Vous avez des questions sur notre entreprise, nos services ou nos produits ? Ne cherchez plus ! Cette section regroupe les réponses aux questions les plus courantes que vous pourriez avoir.<br style={{ display: { xs: 'block', md: 'none' } }} /><br style={{ display: { xs: 'block', md: 'none' } }} /> Nous sommes là pour vous fournir des informations claires et concises afin de vous aider à mieux comprendre qui nous sommes et ce que nous faisons. obtenez les différentes questions et réponses ci-dessous et n'hésitez pas à nous contacter si vous ne trouvez pas la réponse que vous recherchez.</Typography>
                            </Box>
                        </Grid>


                    </Grid>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '20px', marginBottom: '95px', marginTop: { xs: '-70px', md: '-55px' }, flexWrap: 'wrap' }}>
                    <Typography align='center' sx={{ color: '#053961', fontWeight: 'bold' }} variant="h4">NOS EXPERTS VOUS RÉPONDENT</Typography>
                    <Box sx={{ display: { xs: 'none', md: 'block' } }} component={'img'} width='80%' borderRadius={'30px'} src={support} alt='france' />
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', gap: '50px', marginBottom: '50px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' }, gap: '40px' }}>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }} component={'img'} width='30%' height={'auto'} borderRadius={'30px'} src={chaudiere} alt='france' />
                        <Box sx={{ display: { xs: 'block', md: 'none' }, width: { xs: '70%', md: '30%' } }} component={'img'} height={'auto'} borderRadius={'30px'} src={chaudiere} alt='france' />
                        <Box sx={{ textAlign: 'center', width: { xs: '100%', md: '45%' } }}>
                            <Typography sx={{ color: '#053961', fontWeight: 'bold' }} variant="h4">Changez votre chaudière pour une pompe à chaleur !</Typography>
                            <Typography sx={{ color: '#053961', fontWeight: 'light' }} paragraph style={{ marginTop: "15px" }}>Vous vous chauffez au fioul, au gaz, au bois, ou encore à l'électricité ?

                                Si oui, vous êtes probablement éligible à des aides de l'État.

                                Veuillez vérifier votre éligibilité en quelques clics, en haut à droite.</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' }, gap: '40px' }}>
                        <Box sx={{ display: { xs: 'block', md: 'none' }, width: { xs: '70%', md: '30%' } }} component={'img'} height={'auto'} borderRadius={'30px'} src={pompe} alt='france' />
                        <Box sx={{ textAlign: 'center', width: { xs: '100%', md: '45%' } }}>
                            <Typography sx={{ color: '#053961', fontWeight: 'bold' }} variant="h4">Crédit d'impôt pour la pompe à chaleur ? Attention !</Typography>
                            <Typography sx={{ color: '#053961', fontWeight: 'light' }} paragraph style={{ marginTop: "15px" }}>Le crédit d'impôt pour la pompe à chaleur n'existe plus en tant que tel.

                                Vous trouverez ci-dessous plus d'informations concernant les différentes aides que vous pouvez obtenir.

                            </Typography>
                        </Box>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }} component={'img'} width='30%' height={'auto'} borderRadius={'30px'} src={pompe} alt='france' />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' }, gap: '40px' }}>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }} component={'img'} width='30%' height={'auto'} borderRadius={'30px'} src={aidepompe} alt='france' />
                        <Box sx={{ display: { xs: 'block', md: 'none' }, width: { xs: '70%', md: '30%' } }} component={'img'} height={'auto'} borderRadius={'30px'} src={aidepompe} alt='france' />
                        <Box sx={{ textAlign: 'center', width: { xs: '100%', md: '45%' } }}>
                            <Typography sx={{ color: '#053961', fontWeight: 'bold' }} variant="h4">Suis-je éligible aux aides pour la pompe à chaleur ?</Typography>
                            <Typography sx={{ color: '#053961', fontWeight: 'light' }} paragraph style={{ marginTop: "15px" }}> Pour savoir si vous êtes éligible aux différentes aides pour la pompe à chaleur, vous devez remplir le formulaire de demande d'éligibilité.

                                Dès que le gouvernement opère un changement des programmes d'aides,

                                le formulaire est automatiquement mis à jour.

                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' }, gap: '40px' }}>
                        <Box sx={{ display: { xs: 'block', md: 'none' }, width: { xs: '70%', md: '30%' } }} component={'img'} height={'auto'} borderRadius={'30px'} src={financepome} alt='france' />
                        <Box sx={{ textAlign: 'center', width: { xs: '100%', md: '45%' } }}>
                            <Typography sx={{ color: '#053961', fontWeight: 'bold' }} variant="h4">Comment financer ma pompe à chaleur ?</Typography>
                            <Typography sx={{ color: '#053961', fontWeight: 'light' }} paragraph style={{ marginTop: "15px" }}>Vous pourrez bénéficier des aides de l'État.

                                Cependant vous aurez probablement encore un reste à charge, mais rien n'est perdu. Vous pouvez demander un prêt à taux zéro.

                                Vos économies d'énergies suffiront à réaliser un transfert de charges.</Typography>
                        </Box>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }} component={'img'} width='30%' height={'auto'} borderRadius={'30px'} src={financepome} alt='france' />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' }, gap: '40px' }}>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }} component={'img'} width='auto' height={'auto'} borderRadius={'30px'} src={pqpompe} alt='france' />
                        <Box sx={{ display: { xs: 'block', md: 'none' }, width: { xs: '70%', md: '30%' } }} component={'img'} height={'auto'} borderRadius={'30px'} src={pqpompe} alt='france' />
                        <Box sx={{ textAlign: 'center', width: { xs: '100%', md: '45%' } }}>
                            <Typography sx={{ color: '#053961', fontWeight: 'bold' }} variant="h4">Pourquoi installer une pompe à chaleur dans votre maison ?</Typography>
                            <Typography sx={{ color: '#053961', fontWeight: 'light' }} paragraph style={{ marginTop: "15px" }}>Que vous installiez une pompe à chaleur air/air ou bien air/eau, vous réaliserez jusqu'à 75% d'économies de chauffage.</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' }, gap: '40px' }}>
                        <Box sx={{ display: { xs: 'block', md: 'none' }, width: { xs: '70%', md: '30%' } }} component={'img'} height={'auto'} borderRadius={'30px'} src={primepompe} alt='france' />
                        <Box sx={{ textAlign: 'center', width: { xs: '100%', md: '45%' } }}>
                            <Typography sx={{ color: '#053961', fontWeight: 'bold' }} variant="h4">Quelles primes pour l'installation d'une pompe à chaleur ?</Typography>
                            <Typography sx={{ color: '#053961', fontWeight: 'light' }} paragraph style={{ marginTop: "15px" }}>Différentes primes peuvent être accordées et les conditions pour les obtenir diffèrent selon les cas.

                                Ma Prime Rénov' ou encore la Prime Coup De Pouce.

                                L 'Agence A.E.S.E peut également accorder un Bonus Écologique au prorata de ses objectifs à atteindre en fonction de la région concernée.</Typography>
                        </Box>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }} component={'img'} width='30%' height={'auto'} borderRadius={'30px'} src={primepompe} alt='france' />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' }, gap: '40px' }}>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }} component={'img'} width='30%' height={'auto'} borderRadius={'30px'} src={tarif} alt='france' />
                        <Box sx={{ display: { xs: 'block', md: 'none' }, width: { xs: '70%', md: '30%' } }} component={'img'} height={'auto'} borderRadius={'30px'} src={tarif} alt='france' />
                        <Box sx={{ textAlign: 'center', width: { xs: '100%', md: '45%' } }}>
                            <Typography sx={{ color: '#053961', fontWeight: 'bold' }} variant="h4">Quel tarif peut-on espérer pour une pompe à chaleur ?</Typography>
                            <Typography sx={{ color: '#053961', fontWeight: 'light' }} paragraph style={{ marginTop: "15px" }}>Le coût du matériel pour une pompe à chaleur varie du simple au double entre 15 000 et 25 000 €, hors garanti, hors maintenance et hors frais additionnels.



                                L'avantage du programme 2022 proposé par certaines agences vous permet d'accéder à un bouquet de travaux, garanties et maintenance annuelle incluses sur une durée long-termiste.</Typography>
                        </Box>

                    </Box>

                </Box>

                <Box sx={{ maxWidth: '100%', margin: 'auto', marginTop: '150px', marginBottom: '150px' }}>
                    <Grid container alignItems={'center'} spacing={0}>

                        <Grid display={'flex'} alignItems={'center'} flexDirection={'column'} item xs={12} md={6}>
                            <Box component={'img'} width='30%' textAlign={'center'} src={question} alt='france' />
                            <Box sx={{ padding: '25px', textAlign: 'center', p: 6 }}>
                                <Typography sx={{ color: '#053961', fontWeight: 'bold' }} variant="h4">UNE QUESTION ?
                                    <br /> <span style={{ color: '#e43645', fontWeight: 'bold' }}>Envoyez-nous un message !</span></Typography>

                                <Typography sx={{ color: '#053961', fontWeight: 'light' }} variant="h6" style={{ marginTop: "15px" }}>Nos conseiller spécialisés sont à votre disposition 7/7 pour répondre à vos interrogations et vous fournir les informations correspondantes.
                                    Profitez de notre formulaire de contact ci-après pour nous faire part de votre demande.
                                    Nous nous efforçons de vous répondre dans les meilleurs délais.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box className='animate__animated animate__fadeIn'
                                sx={{
                                    padding: '50px',
                                    backgroundColor: '#053961',
                                    marginBottom: '50px',
                                    display: 'flex',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    borderRadius: '30px',
                                    width: '80%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <Typography sx={{ color: '#ffffff', fontWeight: 'bold' }} variant="h5" mb={3}>Poser votre question !</Typography>

                                <form onSubmit={handleSubmit} style={{ display: 'flex', width: '100%', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>
                                    <FormControl className='animate__animated animate__fadeInRight' variant="filled" style={{ backgroundColor: '#ffffff', width: '100%', marginRight: '10px', borderRadius: '10px' }}>
                                        <InputLabel color="secondary">Type de votre question</InputLabel>
                                        <Select required defaultValue='' value={type} onChange={(event) => setType(event.target.value)}>
                                            <MenuItem value={"Equipement et installation"}>Equipement et installation</MenuItem>
                                            <MenuItem value={"SAV"}>SAV</MenuItem>
                                            <MenuItem value={"Service Client"}>Service Client</MenuItem>
                                            <MenuItem value={"Service financier"}>Service financier</MenuItem>
                                            <MenuItem value={"Autre"}>Autre</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <TextField required className='animate__animated animate__fadeInLeft' value={name}
                                        onChange={(event) => setName(event.target.value)} label="Nom/Prénom" variant="filled" style={{ backgroundColor: '#ffffff', width: '100%', marginRight: '10px', borderRadius: '10px' }} />
                                    <TextField required className='animate__animated animate__fadeInLeft' value={email}
                                        onChange={(event) => setEmail(event.target.value)} label="Email" variant="filled" style={{ backgroundColor: '#ffffff', width: '100%', marginRight: '10px', borderRadius: '10px' }} />
                                    <TextField required className='animate__animated animate__fadeInLeft' value={phone}
                                        onChange={(event) => setPhone(event.target.value)} label="Téléphone" variant="filled" style={{ backgroundColor: '#ffffff', width: '100%', marginRight: '10px', borderRadius: '10px' }} />
                                    <TextField required className='animate__animated animate__fadeInRight' value={message}
                                        onChange={(event) => setMessage(event.target.value)} label="Votre question" multiline rows={4} variant="filled" style={{ backgroundColor: '#ffffff', width: '100%', marginRight: '10px', borderRadius: '10px' }} />

                                    <Button type='submit' className='animate__animated animate__fadeInRight' variant="contained" sx={{ backgroundColor: '#609ff2', width: '100%', marginTop: '10px', maxWidth: '100px' }}>Envoyer</Button>
                                </form>
                            </Box>

                        </Grid>

                    </Grid>
                </Box>
            </Box>
        </>
    )
}

export default Question