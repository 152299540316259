import { Grid, Typography, Box } from '@mui/material';
import React from 'react';
import pays from '../assets/map-of-france-3141895_960_720.png';

function Proche() {
    return (
        <Box sx={{ maxWidth: '100%', margin: 'auto' }}>
            <Grid container alignItems={'center'}>
                <Grid item md={6} xs={12}>
                    <Box sx={{ width: '100%', padding: '25px', textAlign: 'center', p: 6, marginLeft: { xs: '0px', md: '150px' } }}>
                        <Typography sx={{ color: '#053961', fontWeight: 'bold' }} variant="h4">Notre agence à proximité de chez vous !
                        </Typography>
                        <Typography sx={{ color: '#053961', fontWeight: 'light', fontSize: { xs: '18px' }, }} variant="h6" style={{ marginTop: "15px" }}>Notre réseau national est étudié de façon à pouvoir vous accompagner à chaque étape de votre projet de rénovation énergétique, en étant au plus proche de vous.
                            Nos  techniciens locaux sont triés sur le volet pour des interventions qualitatives aux quatre coins de la France.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box component='img' width='60%' height='auto' src={pays} alt='france' />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Proche;
