import React, { useState } from 'react'
import Header from '../components/Header'
import Slider1 from '../components/Slider1'
import IconContainer from '../components/IconContainer'
import Mission from '../components/Mission'
import Slider2 from '../components/Slider2'
import CardSuivi from '../components/CardSuivi'
import Produits from '../components/Produits'
import { Element } from 'react-scroll';
import Proche from '../components/Proche'
import Footer from '../components/Footer'
import { ButtonContext } from '../components/ButtonContext'
function Home() {
    const [selectedButton, setSelectedButton] = useState(1);

    return (
        <>
            <header>
                <Header />
            </header>
            <main>
                <section>
                    <ButtonContext.Provider value={{ selectedButton, setSelectedButton }}>
                        <Slider1 />
                        <Mission />

                        <CardSuivi />
                        <IconContainer />
                        <Slider2 />
                        <Element name="products">
                            <Produits />
                        </Element>
                        <Proche />
                    </ButtonContext.Provider>

                </section>
            </main>
            <footer>
                <Footer />
            </footer>

        </>
    )
}

export default Home