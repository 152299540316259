import { Alert, Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material'
import React from 'react'
import EuroIcon from '@mui/icons-material/Euro';
import WindPowerIcon from '@mui/icons-material/WindPower';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import thermo from '../assets/ballon-thermodynamique-viessmann-vitocal-060-A.webp';
import confort from '../assets/41837-01-atlantic.webp';
import choisir from '../assets/audit_energetique.jpeg__5000x3757_q70_subject_location-2500,1881_subsampling-2.webp';
import emailjs from 'emailjs-com';
import useIsMobile from './useIsMobile';
import { Typewriter } from 'react-simple-typewriter';
function Ballon() {
    const [open, setOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('success');
    const isMobile = useIsMobile();
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [workType, setWorkType] = React.useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        // Make fields required
        if (!name || !email || !phone || !postalCode || !workType) {
            setAlertMessage('Veuillez remplir tous les champs.');
            setAlertSeverity('error');
            setOpen(true);
            return;
        }

        const templateParams = {
            name: name,
            email: email,
            phone: phone,
            postalCode: postalCode,
            workType: workType,
        };

        emailjs.send('service_27k6s9b', 'template_kgxrah5', templateParams, "s6Nh9NJ9gJmcA1AL9")
            .then((response) => {
                console.log('Données envoyées avec succès!', response.status, response.text);
                setAlertMessage('Votre message à été envoyé.');
                setAlertSeverity('success');
                setOpen(true);
                // Clear the form
                setName('');
                setEmail('');
                setPhone('');
                setPostalCode('');
                setWorkType('');
            }, (err) => {
                console.error('Erreur lors de l\'envoi des données : ', err);
                setAlertMessage('Erreur lors de l\'envoi du message.');
                setAlertSeverity('error');
                setOpen(true);
            });
    };
    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%', fontSize: '1.5em' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Box sx={{ position: 'relative' }}>
                <Box sx={{ display: { xs: 'none', md: 'block' }, marginBottom: '20px', position: 'absolute', top: -55, left: 0, right: 0, width: '24%', backgroundColor: '#053b63', borderRadius: '0 40px 40px 0', color: 'white', zIndex: 10, padding: '20px', textAlign: 'center' }}>
                    <Typography letterSpacing={"2px"} variant='h6'>Ballon Thermodynamique</Typography>
                </Box>
                <Box sx={{ maxWidth: '100%', margin: 'auto', marginTop: { xs: '50px', md: '170px' } }}>
                    <Grid container alignItems={'center'} spacing={0}>

                        <Grid item xs={12} md={6}>
                            <Box sx={{ padding: '30px', textAlign: 'center', p: 6 }}>
                                <Typography sx={{ color: '#053961', fontWeight: 'bold' }} variant="h4">Un ballon thermodynamique c’est<br />

                                    <span style={{ color: '#e43645', fontWeight: 'bold' }}><Typewriter
                                        words={['et jusqu’à 75% d’économie d’énergie']}
                                        typeSpeed={70}
                                        cursorBlinking={true}
                                        cursorColor="#e43645"
                                        cursor={true}
                                        cursorStyle="|"
                                    /></span></Typography>
                                <Typography sx={{ color: '#053961', fontSize: { xs: '18px' }, fontWeight: 'light' }} paragraph variant="h6" style={{ marginTop: "15px" }}>Le ballon thermodynamique combine une pompe à chaleur et un ballon de stockage. Cette association permet au dispositif de réaliser des économies d’énergie allant jusqu’à 70 % par rapport à un cumulus électrique standard.
                                    Cela induit des factures énergétiques pour la production d’eau chaude sanitaire divisées par 3 ! <br style={{ display: { xs: 'block', md: 'none' } }} /><br style={{ display: { xs: 'block', md: 'none' } }} />
                                    Le réchauffement climatique et les préoccupations environnementales sont des problématiques dont de nombreux foyers se soucient.
                                    Les caractéristiques d’un ballon thermodynamique rendent l’installation éligible à des aides financières dans le cadre d'une transition énergétique.
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box component='img' width='80%' borderRadius={'30% 30% 0 30%'} src={thermo} alt='france' />
                        </Grid>

                    </Grid>
                </Box>
                <Box sx={{ marginTop: '50px' }} className="info-container">
                    <Box className="info-content2 animate__animated animate__backInLeft">
                        <Box className="fa-stack fa-lg icon-container">
                            <i
                                style={{ color: '#053961', fontSize: "22px" }}
                                className="far fa-circle fa-stack-2x"
                            ></i>
                            <i
                                style={{ color: '#e43645', fontSize: '22px' }}
                                className="fas fa-calculator fa-stack-1x fa-inverse"
                            ></i>
                        </Box>

                        <Box className="info-text2">
                            <p>Analyse de votre projet</p>
                            <p>Votre conseiller dédié vous accompagne de A à Z.</p>
                        </Box>
                    </Box>
                    <Box className="info-content2 animate__animated animate__backInRight">
                        <Box className="fa-stack fa-lg icon-container">
                            <i
                                style={{ color: '#053961', fontSize: "22px" }}
                                className="far fa-circle fa-stack-2x"
                            ></i>
                            <i
                                style={{ color: '#e43645', fontSize: '22px' }}
                                className="fas fa-file-lines fa-stack-1x fa-inverse"
                            ></i>
                        </Box>
                        <Box className="info-text2">
                            <p>Audit énergétique</p>
                            <p>Bénéficiez d’une étude à domicile 100% gratuite.</p>
                        </Box>
                    </Box>
                    <Box
                        className="info-content2 animate__animated animate__backInLeft animate__delay-1s"
                    >
                        <Box className="fa-stack fa-lg icon-container">
                            <i
                                style={{ color: '#053961', fontSize: "22px" }}
                                className="far fa-circle fa-stack-2x"
                            ></i>
                            <i
                                style={{ color: '#e43645', fontSize: '22px' }}
                                className="fas fa-coins fa-stack-1x fa-inverse"
                            ></i>
                        </Box>
                        <Box className="info-text2">
                            <p>Dossier d’aides

                            </p>
                            <p>Nous réalisons pour vous une demande auprès de maprimerenov.gouv.fr. </p>
                        </Box>
                    </Box>
                    <Box
                        className="info-content2 animate__animated animate__backInRight animate__delay-1s"
                    >
                        <Box className="fa-stack fa-lg icon-container">
                            <i
                                style={{ color: '#053961', fontSize: "22px" }}
                                className="far fa-circle fa-stack-2x"
                            ></i>
                            <i
                                style={{ color: '#e43645', fontSize: '22px' }}
                                className="fas fa-check fa-stack-1x fa-inverse"
                            ></i>
                        </Box>
                        <Box className="info-text2">
                            <p>Validation du dossier</p>
                            <p>
                                Vous recevez par mail la confirmation de l'acceptation de votre dossier.

                            </p>
                        </Box>
                    </Box>
                    <Box className="info-content2 animate__animated animate__backInRight">
                        <Box className="fa-stack fa-lg icon-container">
                            <i
                                style={{ color: '#053961', fontSize: "22px" }}
                                className="far fa-circle fa-stack-2x"
                            ></i>
                            <i
                                style={{ color: '#e43645', fontSize: '22px' }}
                                className="fas fa-toolbox fa-stack-1x fa-inverse"
                            ></i>
                        </Box>
                        <Box className="info-text2">
                            <p>Début des travaux</p>
                            <p>
                                Nous fixons avec vous une date de départ des travaux et un calendrier de réalisation.
                            </p>
                        </Box>
                    </Box>

                </Box>
                <Box sx={{ maxWidth: '100%', margin: 'auto', marginTop: '50px', marginBottom: '80px' }}>
                    <Grid container alignItems={'center'} spacing={0}>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box component='img' width='80%' borderRadius={'30% 30% 0 30%'} height='auto' src={confort} alt='france' />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ padding: '25px', textAlign: 'center', p: 6 }}>
                                <Typography sx={{ color: '#053961', fontWeight: 'bold', letterSpacing: '3px' }} variant="h5">UNE SOLUTION DE PRODUCTION D’EAU CHAUDE QUI FAIT L'UNANIMITÉ
                                </Typography>
                                <Typography sx={{ color: '#e43645', fontWeight: 'bold' }} variant="h5">Le chauffe-eau thermodynamique est de plus en plus recommandé dans les habitations.
                                </Typography>
                                <Divider sx={{ marginTop: "10px" }} />
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', marginTop: '10px' }}>
                                    <EuroIcon sx={{ color: '#053961', fontSize: '30px' }} />
                                    <Typography sx={{ color: '#053961', fontWeight: 'bold', marginLeft: '15px' }} variant="h6">Économisez jusqu'à 75% sur votre facture énergétique annuelle.
                                    </Typography>
                                </Box>
                                <Divider sx={{ marginTop: "10px" }} />
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', marginTop: '10px' }}>
                                    <WindPowerIcon sx={{ color: '#053961', fontSize: '30px' }} />
                                    <Typography paragraph sx={{ color: '#053961', fontWeight: 'bold', marginLeft: '15px' }} variant="h6">Un ballon thermodynamique utilise l’air pour fonctionner..</Typography>
                                </Box>
                                <Divider sx={{ marginTop: "10px" }} />
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', marginTop: '10px' }}>
                                    <ShowChartIcon sx={{ color: '#053961', fontSize: '35px' }} />
                                    <Typography paragraph sx={{ color: '#053961', fontWeight: 'bold', marginLeft: '15px' }} variant="h6">Une excellente rentabilité et un amortissement visible</Typography>
                                </Box>
                                <Divider sx={{ marginTop: "10px" }} />
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', marginTop: '10px' }}>
                                    <ThumbUpIcon sx={{ color: '#053961', fontSize: '30px' }} />
                                    <Typography paragraph sx={{ color: '#053961', fontWeight: 'bold', marginLeft: '15px' }} variant="h6">Une excellente rentabilité et un amortissement visible</Typography>
                                </Box>
                            </Box>
                        </Grid >
                    </Grid >
                </Box >
                <Box sx={{ maxWidth: '100%', margin: 'auto', marginTop: '50px', marginBottom: '50px' }}>
                    <Grid container alignItems={'center'} spacing={0}>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: { xs: '-60px', md: '50px' } }}>
                            <Box component='img' width='70%' borderRadius={'10px'} src={choisir} alt='france' />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ padding: '25px', textAlign: 'center', p: 6 }}>
                                <Typography sx={{ color: '#e43645', fontWeight: 'bold' }} variant="h5">Quelles différences entre un chauffe-eau électrique et un chauffe-eau thermodynamique ?
                                </Typography>
                                <Divider sx={{ marginTop: "10px" }} />
                                <Typography paragraph sx={{ color: '#053961', fontWeight: 'bold', marginTop: '5px' }} >La consommation d'électricité pour un chauffe-eau 100 % électrique est de 800 kWh/personne/an. Soit, pour un ballon de 300 litres, entre 2000 et 3500 kWh en fonction de la consommation de l'utilisateur. En comparaison, une chauffe-eau thermodynamique consomme trois à quatre fois moins d'énergie.</Typography>
                                <Divider sx={{ marginBottom: "10px" }} />
                                <Typography sx={{ color: '#053961', fontWeight: 'bold' }} paragraph>Remplissez le bref formulaire ci-après pour en discuter davantage par téléphone.</Typography>

                            </Box>
                        </Grid>


                    </Grid>
                </Box >

                <Box className='animate__animated animate__fadeIn'
                    sx={{
                        padding: '50px',
                        backgroundColor: '#053961',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        marginBottom: '50px',
                    }}
                >
                    <Typography variant='h6' sx={{ marginBottom: '10px', color: '#ffffff' }}>Profitez d’un appel gratuit avec un conseiller affilié à votre secteur géographique.</Typography>
                    <form onSubmit={handleSubmit} style={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px' }}>
                        <TextField
                            required
                            value={name}
                            onChange={(event) => setName(event.target.value)} className={`animate__animated ${isMobile ? '' : 'animate__fadeInLeft'}`} label="Nom/Prénom" variant="filled" style={{ backgroundColor: '#ffffff', flex: '1 1 300px', width: '200px', marginRight: '10px', borderRadius: '10px', }} />
                        <TextField
                            required
                            value={email}
                            onChange={(event) => setEmail(event.target.value)} className={`animate__animated ${isMobile ? '' : 'animate__fadeInLeft'}`} label="Email" variant="filled" style={{ backgroundColor: '#ffffff', flex: '1 1 300px', width: '200px', marginRight: '10px', borderRadius: '10px' }} />
                        <TextField
                            required
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)} className={`animate__animated ${isMobile ? '' : 'animate__fadeInLeft'}`} label="Téléphone" variant="filled" style={{ backgroundColor: '#ffffff', flex: '1 1 150px', width: '200px', marginRight: '10px', borderRadius: '10px' }} />
                        <TextField
                            required
                            value={postalCode}
                            onChange={(event) => setPostalCode(event.target.value)} className={`animate__animated ${isMobile ? '' : 'animate__fadeInRight'}`} label="Code postal" variant="filled" style={{ backgroundColor: '#ffffff', flex: '1 1 150px', width: '200px', marginRight: '10px', borderRadius: '10px' }} />
                        <FormControl className={`animate__animated ${isMobile ? '' : 'animate__fadeInRight'}`} variant="filled" style={{ backgroundColor: '#ffffff', flex: '1 1 150px', width: '200px', marginRight: '10px', borderRadius: '10px' }}>
                            <InputLabel color="secondary">Type de travaux</InputLabel>
                            <Select
                                required
                                value={workType}
                                onChange={(event) => setWorkType(event.target.value)} defaultValue="">
                                <MenuItem value={"Panneau Photovoltaïque"}>Panneau Photovoltaïque</MenuItem>
                                <MenuItem value={"Ballon thermodynamique"}>Ballon thermodynamique</MenuItem>
                                <MenuItem value={"PAC Air-eau"}>PAC Air-eau</MenuItem>
                                <MenuItem value={"PAC Air-air"}>PAC Air-air</MenuItem>
                                <MenuItem value={"Rénovation globale"}>Rénovation globale</MenuItem>
                                <MenuItem value={"Autre"}>Autre</MenuItem>
                            </Select>
                        </FormControl>
                        <Button type="submit" className={`animate__animated ${isMobile ? '' : 'animate__fadeInRight'}`} variant="contained" sx={{ backgroundColor: '#609ff2', flex: '1 1 100px', maxWidth: '100px' }}>Envoyer</Button>
                    </form>
                </Box>
            </Box>
        </>
    )
}

export default Ballon