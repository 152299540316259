import { Box, Typography } from '@mui/material'
import React, { useContext } from 'react'
import panneau from '../assets/panneau.webp';
import pompe from '../assets/pac.png';
import { ButtonContext } from './ButtonContext';
import { scroller } from 'react-scroll';
import ballon from '../assets/s-serie_vater-tochter_nibe-waermepumpen-schweiz_800x800px.jpg';
import renovation from '../assets/Recrutement Alphea Conseil BTP.png'
function CardSuivi() {

    const { setSelectedButton } = useContext(ButtonContext);

    const handleClick = (button) => {
        setSelectedButton(button);
        scroller.scrollTo('products', { duration: 1000, smooth: true });
    };
    return (
        <>

            <Box className="section-card-header">
                <Typography align='center' sx={{
                    fontWeight: '600',
                    letterSpacing: { xs: 'inherit', md: '4px' },
                    textTransform: "uppercase",
                    lineHeight: { xs: 'inherit', md: '1.4px' }
                }} variant="h4">Réaliser votre projet</Typography>
                <Typography textAlign={'center'} variant="h6">
                    Des aides de l'État existent pour financer vos travaux.
                    <span>Interrogez-nous !</span>
                </Typography>
            </Box>
            <Box sx={{ marginBottom: '50px' }} className="section-card-container">

                <Box className="cards" onClick={() => handleClick(2)} >
                    <Box className="card-img">
                        <img
                            src={renovation}
                            alt=""
                        />
                    </Box>
                    <Box className="card-content">
                        <Box sx={{ width: '100%' }}><Typography sx={{ color: 'white', fontSize: '18px', }} variant="h6">(AE) Audit Énergétique</Typography></Box>

                    </Box>

                </Box>
                <Box className="cards" onClick={() => handleClick(4)}>
                    <Box className="card-img">
                        <img
                            src={ballon}
                            alt=""
                        />
                    </Box>
                    <Box className="card-content">
                        <Box sx={{ width: '100%' }}>
                            <Typography sx={{ color: 'white', fontSize: '18px', }} variant="h6">(BTD) Ballon thermodynamique</Typography>
                        </Box>

                    </Box>

                </Box>
                <Box className="cards" onClick={() => handleClick(1)}>
                    <Box className="card-img">
                        <img
                            src={panneau}
                            alt=""
                        />
                    </Box>
                    <Box className="card-content">
                        <Box sx={{ width: '100%' }}><Typography sx={{ color: 'white', fontSize: '18px', fontWeight: 'bold' }} variant="h6">(PV) Panneaux photovoltaïques </Typography></Box>



                    </Box>

                </Box>
                <Box className="cards" onClick={() => handleClick(5)}>
                    <Box className="card-img">
                        <img
                            src={pompe}
                            alt=""
                        />
                    </Box>
                    <Box className="card-content">
                        <Box sx={{ width: '100%' }}>
                            <Typography sx={{ color: 'white', fontSize: '18px', }} variant="h6">(PAC) Pompe à Chaleur</Typography>
                        </Box>

                    </Box>

                </Box>

            </Box>
        </>
    )
}

export default CardSuivi