import React, { useState } from 'react';
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from '@mui/material';


function Footer() {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box
            component="footer"
            sx={{
                width: '100%',
                backgroundColor: '#053961',
                color: 'white',
                p: 3,
                textAlign: 'center'
            }}
        >
            <Typography component="p">Copyright © 2023 - Agence AESE</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <Button onClick={handleClickOpen}>
                    <Typography sx={{ fontSize: '12px', textDecoration: 'underline', color: 'white', transition: 'all 0.3s ease-in-out', cursor: 'pointer' }}>Politique de confidentialité</Typography>
                </Button>

            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <DialogTitle>
                    {"Code de déontologie"}

                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText component={'div'}>
                        <Typography variant='h4'>POLITIQUE DE CONFIDENTIALITÉ</Typography>
                        <p> <span style={{ fontWeight: 'bold' }}>1. Généralités</span> Les informations contenues sur ce site sont aussi précises que possible et le site remis à jour à différentes périodes de l’année, mais peut toutefois contenir des inexactitudes ou des omissions.
                            L'Éditeur s’engage à fournir les meilleurs efforts pour le maintien en ligne et le maintien à jour des services proposés sur le Site</p>
                        <p> <span style={{ fontWeight: 'bold' }}>2. Données personnelles</span> L'Éditeur s'engage à :
                            Assurer le respect des droits fondamentaux des Utilisateurs lors de la collecte de données personnelles sur le Site
                            Tenir les Utilisateurs informés de toute collecte et traitement des données personnelles qui le concernent : les données personnelles transmises par l'Utilisateur sur le Site peuvent être transférées à un tiers
                            Conformément à la loi Informatique et Libertés du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès et de rectification des données personnelles et nominatives qu'il a transmises à l'Éditeur. </p>
                        <p> <span style={{ fontWeight: 'bold' }}>3. Garantie vis-à-vis des Utilisateurs</span> L'Éditeur s'engage à : Déployer tous les moyens à sa portée dans la mesure du possible pour répondre aux questions des Utilisateurs. Faire ses meilleurs efforts pour garantir la fiabilité, la pertinence et l'exactitude des contenus pouvant être produits par des tiers et diffusés sur le Site. Répondre à toutes questions relatives à notre activité</p>
                        <p> <span style={{ fontWeight: 'bold' }}>4. Propriété intellectuelle</span> Tout le contenu du présent site incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société à l’exception des marques, logos ou contenus appartenant à d’autres sociétés partenaires ou auteurs. Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l’accord exprès par écrit de l'AGENCE ENVIRONNEMENTALE POUR SOLUTIONS ENERGETIQUES. Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur. En outre, les propriétaires des Contenus copiés pourraient intenter une action en justice à votre encontre.</p>
                        <p> <span style={{ fontWeight: 'bold' }}>5. Litiges</span> Les présentes conditions du site sont régies par les lois françaises et toute contestation ou litiges qui pourraient naître de l’interprétation ou de l’exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de la société. La langue de référence, pour le règlement de contentieux éventuels, est le français.</p>
                        <p> <span style={{ fontWeight: 'bold' }}>6. Nos conseils pour une navigation sûre</span> Une connexion stable pour éviter d’être déconnecté lors du remplissage des questionnaires.
                            Attention aux éléments attachés à des emails de provenance inconnue, ils peuvent contenir des virus.
                            7. Utilisation des cookies</p>
                        <p> <span style={{ fontWeight: 'bold' }}>7. Limitations contractuelles sur les données</span> Tout contenu téléchargé se fait aux risques et périls de l’utilisateur et sous sa seule responsabilité. En conséquence, ne saurait être tenu responsable d’un quelconque dommage subi par l’ordinateur de l’utilisateur ou d’une quelconque perte de données consécutives au téléchargement. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour. Les liens hypertextes mis en place dans le cadre du présent site internet en direction d’autres ressources présentes sur le réseau Internet ne sauraient engager la responsabilité de AGENCE ENVIRONNEMENTALE POUR SOLUTIONS ENERGETIQUES.</p>
                        <p> <span style={{ fontWeight: 'bold' }}>8. Liens des articles qui parlent des aides gouvernementales.</span>BFMtv : https://rmc.bfmtv.com/actualites/economie/conso/ma-prime-renov-plusieurs-dossiers-se-debloquent-enfin-grace-a-rmc_AV-202111080551.html
                            LeProgrès : https://www.leprogres.fr/economie/2021/11/02/maprimerenov-pour-la-renovation-energetique-la-quantite-plutot-que-la-qualite
                            LCI : https://www.tf1info.fr/conso-argent/fiscalite-verte-travaux-renovation-energetique-cite-eco-pret-taux-zero-carburant-diesel-essenceprime-a-la-conversion-bonus-electrique-ce-qui-change-budget-2019-2099476.html
                            LEXPRESS : https://lexpansion.lexpress.fr/actualite-economique/maprimerenov-et-le-difficile-chantier-de-la-renovation-energetique_2143534.html
                            Aussi, conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la confiance dans l’économie numérique, les responsables du présent site internet sont :
                            Code APE Numéro de TVA intracommunautaire : FR75447860370
                            Siège Social : 13 bis Rue de l'Abreuvoir, 92400 Courbevoie
                            SIRET Etablissement : 44786037000033
                            Standard Directeur de la publication : Victor Sebastia
                            Vous souhaitez un site similaire ? contactez-nous à victoraese@gmail.com
                            Egalement pour toute information complémentaire concernant le site www.agenceaese.com</p>


                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ mb: 2, mr: 2, backgroundColor: 'black', color: 'white', padding: '8px', '&:hover': { backgroundColor: 'black', color: 'white' } }} onClick={handleClose}>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </Box >
    );
}

export default Footer;
