import { Box } from '@mui/material'
import React from 'react'
import useIsMobile from './useIsMobile';
function IconContainer() {
    const isMobile = useIsMobile();
    return (

        <Box className="info-container">

            <Box className={`info-content animate__animated ${isMobile ? '' : 'animate__backInLeft'}`}>
                <Box className="fa-stack fa-lg icon-container">
                    <i
                        style={{ color: '#053961', fontSize: "22px" }}
                        className="far fa-circle fa-stack-2x"
                    ></i>
                    <i
                        style={{ color: '#e43645', fontSize: '22px' }}
                        className="fas fa-angle-up fa-stack-1x fa-inverse"
                    ></i>
                </Box>

                <Box className="info-text">
                    <p>L'excellence et l'expérience au service des particuliers</p>
                </Box>
            </Box>
            <Box
                className={`info-content animate__animated ${isMobile ? '' : 'animate__backInLeft animate__delay-1s'}`}
            >
                <Box className="fa-stack fa-lg icon-container">
                    <i
                        style={{ color: '#053961', fontSize: "22px" }}
                        className="far fa-circle fa-stack-2x"
                    ></i>
                    <i
                        style={{ color: '#e43645', fontSize: '22px' }}
                        className="fas fa-map-marked fa-stack-1x fa-inverse"
                    ></i>
                </Box>
                <Box className="info-text">
                    <p>Une couverture nationale pour des interventions locales
                    </p>
                </Box>
            </Box>
            <Box
                className={`info-content animate__animated ${isMobile ? '' : 'animate__backInRight animate__delay-1s'}`}
            >
                <Box className="fa-stack fa-lg icon-container">
                    <i
                        style={{ color: '#053961', fontSize: "22px" }}
                        className="far fa-circle fa-stack-2x"
                    ></i>
                    <i
                        style={{ color: '#e43645', fontSize: '22px' }}
                        className="fas fa-history fa-stack-1x fa-inverse"
                    ></i>
                </Box>
                <Box className="info-text">
                    <p>
                        Des sites logistiques pour optimiser la réalisation de vos projets
                    </p>
                </Box>
            </Box>
            <Box
                className={`info-content animate__animated ${isMobile ? '' : 'animate__backInRight animate__delay-1s'}`}
            >
                <Box className="fa-stack fa-lg icon-container">
                    <i
                        style={{ color: '#053961', fontSize: "22px" }}
                        className="far fa-circle fa-stack-2x"
                    ></i>
                    <i
                        style={{ color: '#e43645', fontSize: '22px' }}
                        className="fas fa-thumbs-up fa-stack-1x fa-inverse"
                    ></i>
                </Box>
                <Box className="info-text">
                    <p>92% de nos clients nous parviennent sur recommandation
                    </p>
                </Box>
            </Box>
        </Box>
    )
}

export default IconContainer