import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { scroller } from 'react-scroll';
import pompe from '../assets/S2125-trotz-dem-winter_nibe-waermepumpen-schweiz_1600x470px.jpg';
import ballon from '../assets/ballon3.jpg';
import audit from '../assets/comment_se_deroule_audit_energetique-e1597841159600.jpg';
import iso from '../assets/ite-avec-groupe-verlaine-ite.jpg';
import affi from '../assets/banniere-parrainage.jpg';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import panneau from '../assets/panneauPhotovoltaiques.png';
import { ButtonContext } from './ButtonContext';
function Produits() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { selectedButton, setSelectedButton } = useContext(ButtonContext);


    const handleClick = (button) => {
        setSelectedButton(button);
        scroller.scrollTo('products', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    };

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: '50px'
        }}>
            <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '20px', padding: { xs: '10px' } }}>
                <Button variant='outlined' sx={{
                    width: isMobile ? 'calc(50% - 10px)' : 'auto',
                    color: selectedButton === 1 ? 'white' : '#053961',
                    backgroundColor: selectedButton === 1 ? '#053961' : 'white', borderColor: '#053961', padding: '15px', "&:hover": { color: 'white', backgroundColor: '#053961', borderColor: 'white' }
                }} onClick={() => handleClick(1)}>PANNEAU SOLAIRE</Button>
                <Button variant='outlined' sx={{
                    width: isMobile ? 'calc(50% - 10px)' : 'auto',
                    color: selectedButton === 2 ? 'white' : '#053961',
                    backgroundColor: selectedButton === 2 ? '#053961' : 'white', borderColor: '#053961', padding: '15px', "&:hover": { color: 'white', backgroundColor: '#053961', borderColor: 'white' }
                }} onClick={() => handleClick(2)}>Audit énergétique</Button>
                <Button variant='outlined' sx={{
                    width: isMobile ? 'calc(50% - 10px)' : 'auto',
                    color: selectedButton === 3 ? 'white' : '#053961',
                    backgroundColor: selectedButton === 3 ? '#053961' : 'white', borderColor: '#053961', padding: '15px', "&:hover": { color: 'white', backgroundColor: '#053961', borderColor: 'white' }
                }} onClick={() => handleClick(3)}>Isolation thermique</Button>
                <Button variant='outlined' sx={{
                    width: isMobile ? 'calc(50% - 10px)' : 'auto',
                    color: selectedButton === 4 ? 'white' : '#053961',
                    backgroundColor: selectedButton === 4 ? '#053961' : 'white', borderColor: '#053961', padding: '15px', "&:hover": { color: 'white', backgroundColor: '#053961', borderColor: 'white' }
                }} onClick={() => handleClick(4)}>Ballon thermodynamique</Button>
                <Button variant='outlined' sx={{
                    width: isMobile ? 'calc(50% - 10px)' : 'auto',
                    color: selectedButton === 5 ? 'white' : '#053961',
                    backgroundColor: selectedButton === 5 ? '#053961' : 'white', borderColor: '#053961', padding: '15px', "&:hover": { color: 'white', backgroundColor: '#053961', borderColor: 'white' }
                }} onClick={() => handleClick(5)}>Pompe à chaleur</Button>
                <Button variant='outlined' sx={{
                    width: isMobile ? 'calc(50% - 10px)' : 'auto',
                    color: selectedButton === 6 ? 'white' : '#053961',
                    backgroundColor: selectedButton === 6 ? '#053961' : 'white', borderColor: '#053961', padding: '15px', "&:hover": { color: 'white', backgroundColor: '#053961', borderColor: 'white' }
                }} onClick={() => handleClick(6)}>PARRAINAGES</Button>
            </Box>
            <Box>
                {selectedButton === 1 &&
                    <Grid container sx={{ mb: '20px', gap: '20px' }} justifyContent="center" alignItems="center">
                        <Grid sx={{ height: { xs: 'auto', md: '300px' } }} item xs={10} md={8}>
                            <Box component='img' borderRadius={'10px 10px 0px 0px'} width='100%' sx={{ height: { xs: 'auto', md: '100%' }, }} src={panneau} alt='panneau photovoltaiques' />
                        </Grid>
                        <Grid item xs={10} md={8}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography paragraph>
                                    Les panneaux solaires photovoltaïques captent les ressources naturelles pour alimenter nos appareils. Ainsi, vos dispositifs fonctionneront grâce à l'énergie solaire.
                                    L'électricité produite est utilisée en priorité pour réduire votre consommation et maîtriser vos factures d'énergie.

                                    N'hésitez pas à discuter de votre projet avec l'un de nos conseillers. Ils sont là pour vous aider et vous fournir toutes les informations nécessaires.

                                </Typography>
                                <Box sx={{ mt: '10px', display: 'flex', gap: '10px' }}>
                                    <NavLink to='/contact' style={{ textDecoration: 'none' }}>    <Button sx={{ backgroundColor: '#609ff2', color: 'white', padding: '10px', '&:hover': { backgroundColor: '#4e8dd6', color: 'white' } }}>Parler à un conseiller</Button></NavLink>

                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                }
                {selectedButton === 2 &&
                    <Grid container sx={{ mb: '20px', gap: '20px' }} justifyContent="center" alignItems="center">
                        <Grid sx={{ height: { xs: 'auto', md: '300px' } }} item xs={10} md={8}>
                            <Box component='img' borderRadius={'10px 10px 0px 0px'} width='100%' sx={{ height: { xs: 'auto', md: '100%' }, }} src={audit} alt='panneau photovoltaiques' />
                        </Grid>
                        <Grid item xs={10} md={8}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography paragraph>
                                    Optimisez votre rénovation énergétique grâce à notre service d'audit énergétique. L'audit énergétique est un outil essentiel pour les propriétaires, offrant une aide à la prise de décision éclairée. Il permet d'évaluer la consommation énergétique d'une habitation, d'estimer son potentiel énergétique et de proposer des solutions de travaux détaillées ainsi que des prévisions financières pour leur réalisation.

                                    N'hésitez pas à discuter de votre projet avec l'un de nos conseillers. Ils sont là pour vous aider et vous fournir toutes les informations nécessaires.

                                </Typography>
                                <Box sx={{ mt: '10px', display: 'flex', gap: '10px' }}>
                                    <NavLink to='/contact' style={{ textDecoration: 'none' }}> <Button sx={{ backgroundColor: '#609ff2', color: 'white', padding: '10px', '&:hover': { backgroundColor: '#4e8dd6', color: 'white' } }}>Parler à un conseiller</Button></NavLink>

                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                }
                {selectedButton === 3 &&
                    <Grid container sx={{ mb: '20px', gap: '20px' }} justifyContent="center" alignItems="center">
                        <Grid sx={{ height: { xs: 'auto', md: '300px' } }} item xs={10} md={8}>
                            <Box component='img' borderRadius={'10px 10px 0px 0px'} width='100%' sx={{ height: { xs: 'auto', md: '100%' }, }} src={iso} alt='panneau photovoltaiques' />
                        </Grid>
                        <Grid item xs={10} md={8}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography paragraph>
                                    L'isolation des murs extérieurs est souvent négligée malgré les avantages significatifs qu'elle offre, tant en été qu'en hiver, même si de plus en plus de logements sont équipés de climatiseurs pour faire face à la chaleur estivale.

                                    Contrairement à l'isolation des murs intérieurs, l'isolation thermique par l'extérieur (ITE) ne réduit pas votre surface habitable. De plus, elle ne nécessite pas que vous quittiez votre logement pendant les travaux. Ainsi, vous pouvez continuer à vivre normalement tout en observant la rénovation de vos façades.

                                    N'hésitez pas à discuter de votre projet avec l'un de nos conseillers. Ils seront à votre disposition pour vous fournir des informations et des conseils personnalisés.
                                </Typography>
                                <Box sx={{ mt: '10px', display: 'flex', gap: '10px' }}>
                                    <NavLink to='/contact' style={{ textDecoration: 'none' }}> <Button sx={{ backgroundColor: '#609ff2', color: 'white', padding: '10px', '&:hover': { backgroundColor: '#4e8dd6', color: 'white' } }}>Parler à un conseiller</Button></NavLink>

                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                }
                {selectedButton === 4 &&
                    <Grid container sx={{ mb: '20px', gap: '20px' }} justifyContent="center" alignItems="center">
                        <Grid sx={{ width: '100%', height: { xs: 'auto', md: '300px' } }} item xs={10} md={8}>
                            <Box component='img' borderRadius={'10px 10px 0px 0px'} width='100%' sx={{ height: { xs: 'auto', md: '100%' } }} src={ballon} alt='panneau photovoltaiques' />
                        </Grid>
                        <Grid item xs={10} md={8}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography paragraph>
                                    Le principe de la production d'eau chaude sanitaire thermodynamique repose sur l'application d'un système de pompe à chaleur à un ballon d'eau chaude sanitaire. Les CET sont éligibles aux aides financières et consomment environ trois fois moins d'énergie qu'un chauffe-eau électrique classique. En optant pour cette solution, vous pouvez réaliser d'importantes économies tout en préservant l'environnement.

                                    N'hésitez pas à discuter de votre projet avec l'un de nos conseillers. Ils sont là pour vous aider et vous fournir toutes les informations nécessaires.

                                </Typography>
                                <Box sx={{ mt: '10px', display: 'flex', gap: '10px' }}>
                                    <NavLink to='/contact' style={{ textDecoration: 'none' }}> <Button sx={{ backgroundColor: '#609ff2', color: 'white', padding: '10px', '&:hover': { backgroundColor: '#4e8dd6', color: 'white' } }}>Parler à un conseiller</Button></NavLink>

                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                }
                {selectedButton === 5 &&
                    <Grid container sx={{ mb: '20px', gap: '20px' }} justifyContent="center" alignItems="center">
                        <Grid sx={{ height: { xs: 'auto', md: '300px' } }} item xs={10} md={8}>
                            <Box component='img' borderRadius={'10px 10px 0px 0px'} width='100%' sx={{ height: { xs: 'auto', md: '100%' }, }} src={pompe} alt='panneau photovoltaiques' />
                        </Grid>
                        <Grid item xs={10} md={8}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography paragraph>
                                    La pompe à chaleur est une alternative efficace et économique aux systèmes de chauffage traditionnels.
                                    Grâce à son rendement élevé, elle permet de réaliser des économies significatives sur votre facture énergétique. En choisissant une pompe à chaleur vous optez pour une solution respectueuse de l'environnement.

                                    N'hésitez pas à discuter de votre projet avec l'un de nos conseillers. Ils sont là pour vous aider et vous fournir toutes les informations nécessaires.

                                </Typography>
                                <Box sx={{ mt: '10px', display: 'flex', gap: '10px' }}>
                                    <NavLink to='/contact' style={{ textDecoration: 'none' }}> <Button sx={{ backgroundColor: '#609ff2', color: 'white', padding: '10px', '&:hover': { backgroundColor: '#4e8dd6', color: 'white' } }}>Parler à un conseiller</Button></NavLink>

                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                }
                {selectedButton === 6 &&
                    <Grid container sx={{ mb: '20px', gap: '20px' }} justifyContent="center" alignItems="center">
                        <Grid sx={{ height: { xs: 'auto', md: '300px' } }} item xs={10} md={8}>
                            <Box component='img' borderRadius={'10px 10px 0px 0px'} width='100%' sx={{ height: { xs: 'auto', md: '100%' }, }} src={affi} alt='panneau photovoltaiques' />
                        </Grid>
                        <Grid item xs={10} md={8}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography paragraph>
                                    Il vous suffit d'avoir déjà bénéficié des services de A.E.S.E puis de partager nos coordonnées à vos proches, collègues etc..

                                    Bénéficiez d’une enveloppe de 500 € pour chaque recommandation, vous recevrez celle-ci sous 30 jours après installation de votre ou vos filleuls.

                                    N'hésitez pas à discuter de votre projet avec l'un de nos conseillers. Ils sont là pour vous aider et vous fournir toutes les informations nécessaires.

                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Box >
    )
}

export default Produits
