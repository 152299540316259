import React from 'react'
import { Autoplay, EffectFade, Pagination, Mousewheel, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import '../style/components/slider2.scss'
import 'swiper/css'
import conseil from '../assets/hGaw0_afEtlj6BGISBpkP-transformed.jpeg'
import rge from '../assets/deux-hommes-casques-travaux-renovation-maison-plans.jpg';
import equip from '../assets/materiel3.jpeg';
import background from '../assets/backrouge2.jpg';
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material'
function useMobileDetect() {
    const [isMobile, setIsMobile] = React.useState(false);




    React.useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return isMobile;
}
function Slider2() {
    const isMobile = useMobileDetect();
    return (

        <>


            <Box className='backslider2' sx={{ width: "100%", marginBottom: "100px", backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
                <Swiper className='swiper2'
                    modules={[Autoplay, EffectFade, Pagination, Mousewheel, Navigation]}
                    autoplay={{ delay: 5000, disableOnInteraction: true }}
                    navigation
                    loop={true}
                    direction='horizontal'
                    slidesPerView={1}
                    effect='fade'

                    fadeEffect={{ crossFade: true }}



                    myclassname="swiper2"

                >
                    <SwiperSlide>
                        <Grid container sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            <Grid item xs={12} md={6} sx={{ flex: 1 }}>
                                <Card sx={{ height: '500px	', borderRadius: '20px 0px 0px 20px' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            alt="Image 1"
                                            height="500px"
                                            image={conseil}
                                        />
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ flex: 1 }}>
                                <Card sx={{ height: '500px', borderRadius: '0px 20px 20px 0px' }}>
                                    <CardContent sx={{ padding: '35px', textAlign: "center" }}>
                                        <Typography gutterBottom variant="h5" sx={{
                                            fontSize: "31px",/* font size increased */
                                            color: "#609ff2", /* color adjusted */
                                            fontFamily: "Roboto, sans-serif",
                                            letterSpacing: "2px",
                                            marginBottom: "20px",
                                            textAlign: "center",
                                        }}>
                                            Votre conseiller dédié disponible 7/7
                                        </Typography>
                                        <Typography paragraph sx={{
                                            fontSize: ['1.5vw', '1.5vh'],
                                            color: "#666",
                                            lineHeight: "2",
                                            '@media (max-width:1398px)': {
                                                fontSize: '13px',
                                            },
                                            '@media (min-width:1200px)': {
                                                fontSize: '19px',
                                            },
                                        }}>
                                            Les conseillers spécialisés mis à votre disposition sont experts en rénovation énergétique. Ils sauront vous orienter vers la solution la plus adaptée à votre projet pour un résultat optimal. Ils vous informeront sur les aides financières pour lesquelles vous êtes, éligibles et simuleront pour vous les économies d’énergie que vous réaliserez grâce à vos nouveaux équipements.
                                            Votre conseiller dédié vous proposera une visite à domicile 100% gratuite afin d’évaluer sur place la faisabilité de votre projet.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </SwiperSlide>

                    <SwiperSlide>
                        <Grid container sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            <Grid item xs={12} md={6} sx={{ flex: 1 }}>
                                <Card sx={{ height: '500px	', borderRadius: '20px 0px 0px 20px' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            alt="Image 1"
                                            height="500px"
                                            image={rge}
                                        />
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ flex: 1 }}>
                                <Card sx={{ height: '500px', borderRadius: '0px 20px 20px 0px' }}>
                                    <CardContent sx={{ padding: '35px', textAlign: "center" }}>
                                        <Typography gutterBottom variant="h5" sx={{
                                            fontSize: "31px",/* font size increased */
                                            color: "#609ff2", /* color adjusted */
                                            fontFamily: "Roboto, sans-serif",
                                            letterSpacing: "2px",
                                            marginBottom: "20px",
                                            textAlign: "center",
                                        }}>
                                            Qualifications RGE, équipes locales
                                        </Typography>
                                        <Typography paragraph sx={{
                                            fontSize: ['1.5vw', '1.5vh'],
                                            color: "#666",
                                            lineHeight: "2",
                                            '@media (max-width:1398px)': {
                                                fontSize: '13px',
                                            },
                                            '@media (min-width:1200px)': {
                                                fontSize: '19px',
                                            },
                                        }}>
                                            Avec A.E.S.E vos travaux d’installation sont effectués uniquement par une entreprise certifiée RGE. Il y a deux raisons à cela. La première raison concerne l’assurance de la qualité de vos travaux. La seconde est que le Label RGE vous permet de financer la totalité ou une partie de votre projet.
                                            Il existe différentes aides et subventions afin de réaliser vos travaux de rénovation énergétique qui ne vous seront pas accessibles en contactant un artisan local qui ne détient pas cette certification.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </SwiperSlide>

                    <SwiperSlide>
                        <Grid container sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            <Grid item xs={6} md={6} sx={{ flex: 1 }}>
                                <Card sx={{ height: '500px	', borderRadius: '20px 0px 0px 20px' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            alt="Image 1"
                                            height="500px"
                                            image={equip}
                                        />
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid item xs={6} md={6} sx={{ flex: 1 }}>
                                <Card sx={{ height: '500px', borderRadius: '0px 20px 20px 0px' }}>
                                    <CardContent sx={{ padding: '35px', textAlign: "center" }}>
                                        <Typography gutterBottom variant="h5" sx={{
                                            fontSize: "31px",/* font size increased */
                                            color: "#609ff2", /* color adjusted */
                                            fontFamily: "Roboto, sans-serif",
                                            letterSpacing: "2px",
                                            marginBottom: "20px",
                                            textAlign: "center",
                                        }}>
                                            Matériels et équipement de haute qualité
                                        </Typography>
                                        <Typography paragraph sx={{
                                            fontSize: ['1.5vw', '1.5vh'],
                                            color: "#666",
                                            lineHeight: "2",
                                            '@media (max-width:1398px)': {
                                                fontSize: '13px',
                                            },
                                            '@media (min-width:1200px)': {
                                                fontSize: '19px',
                                            },
                                        }}>
                                            A.E.S.E collabore avec les marques leaders du marché pour des travaux en toute sérénité !
                                            Notre sélection d’équipements repose sur des critères très rigoureux : qualité, certification et conformité aux normes exigées par la législation, performance et garantie fabricant.
                                            Nous accordons une attention particulière à la recherche d’équipements innovants afin de vous faire profiter des meilleures technologies du moment.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </SwiperSlide>



                    {/* Add more SwiperSlides as necessary... */}

                </Swiper>
            </Box >

            <Box className='slider-adaptation'>
                <Grid container alignItems={'center'}>
                    <Box>


                        <Grid item xs={12} md={6}>
                            <Box component='img' sx={{ width: '100%', objectFit: 'cover', objectPosition: 'top', height: '240px' }} src={conseil} />

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box>

                                <Typography mb={2} mt={2} align='center' variant="h5"><span style={{ color: "#609ff2" }}>Votre conseiller dédié 7/7</span></Typography>
                                <Typography paragraph p={2} align='center'>
                                    Les conseillers spécialisés mis à votre disposition sont experts en rénovation énergétique. Ils sauront vous orienter vers la solution la plus adaptée à votre projet pour un résultat optimal. Ils vous informeront sur les aides financières pour lesquelles vous êtes, éligibles et simuleront pour vous les économies d’énergie que vous réaliserez grâce à vos nouveaux équipements.
                                    Votre conseiller dédié vous proposera une visite à domicile 100% gratuite afin d’évaluer sur place la faisabilité de votre projet.
                                </Typography>
                            </Box>
                        </Grid>
                    </Box>


                </Grid>
            </Box>
        </>
    )
}

export default Slider2