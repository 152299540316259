import { Alert, Box, Button, Checkbox, FormControlLabel, FormLabel, Grid, MenuItem, RadioGroup, Select, Snackbar, TextField, Typography } from '@mui/material';

import React from 'react';
import emailjs from 'emailjs-com';
function Verifier() {
    const [open, setOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('success');

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');

    const [phone, setPhone] = React.useState('');
    const [type, setType] = React.useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        // Make fields required
        if (!name || !email || !phone || !type) {
            setAlertMessage('Veuillez remplir tous les champs.');
            setAlertSeverity('error');
            setOpen(true);
            return;
        }

        const templateParams = {
            type: type,
            name: name,

            email: email,
            phone: phone,
        };

        emailjs.send('service_27k6s9b', 'template_pjamw1l', templateParams, "s6Nh9NJ9gJmcA1AL9")
            .then((response) => {
                console.log('Données envoyées avec succès!', response.status, response.text);
                setAlertMessage('Votre message à été envoyé.');
                setAlertSeverity('success');
                setOpen(true);
                // Clear the form
                setType('');
                setName('');

                setEmail('');
                setPhone('');

            }, (err) => {
                console.error('Erreur lors de l\'envoi des données : ', err);
                setAlertMessage('Erreur lors de l\'envoi du message.');
                setAlertSeverity('error');
                setOpen(true);
            });
    };
    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%', fontSize: '1.5em' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Box sx={{ position: 'relative' }}>
                <Box sx={{ display: { xs: 'none', md: 'block' }, marginBottom: '20px', position: 'absolute', top: -55, left: 0, right: 0, width: '15%', backgroundColor: '#053b63', borderRadius: '0 40px 40px 0', color: 'white', zIndex: 10, padding: '20px', textAlign: 'center' }}>
                    <Typography letterSpacing={"2px"} variant='h6'>Mon éligibilité</Typography>
                </Box>
                <Box sx={{ maxWidth: '100%', margin: 'auto', marginTop: { xs: '90px', md: '170px' }, marginBottom: '130px' }}>
                    <Typography align="center" sx={{ color: "#cbe5ae" }} letterSpacing={"2px"} variant="h4" mb={2}>Soumettre ma demande d'éligibilité</Typography>
                    <Typography align="center" color={'#e43645'} fontWeight={'bold'} variant="h6" mb={2}>Demande d’information à l’éligibilité d’une ou plusieurs aides de l'État.</Typography>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={6}>
                            <Box className='animate__animated animate__fadeIn'
                                sx={{
                                    padding: '30px',
                                    backgroundColor: '#053961',
                                    marginBottom: '50px',
                                    borderRadius: '30px',
                                    width: { xs: '90%', md: '100%' },
                                    mx: 'auto',
                                }}
                            >
                                <form onSubmit={handleSubmit} style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                                    <FormLabel component="legend" sx={{ color: '#ffffff' }}>Type de chauffage actuel</FormLabel>
                                    <Select sx={{ backgroundColor: '#ffffff' }} value={type} required onChange={(e) => setType(e.target.value)}>
                                        <MenuItem sx={{ color: 'black' }} value="Gaz" control={<Checkbox />} label="Gaz">Gaz</MenuItem>
                                        <MenuItem sx={{ color: 'black' }} value="Fioul" control={<Checkbox />} label="Fioul">Fioul</MenuItem>
                                        <MenuItem sx={{ color: 'black' }} value="Electricite" control={<Checkbox />} label="Électricité" >Électricité</MenuItem>
                                        <MenuItem sx={{ color: 'black' }} value="Autre" control={<Checkbox />} label="Autre">Autre</MenuItem>
                                    </Select>

                                    <TextField required value={name} onChange={(e) => setName(e.target.value)} className='animate__animated animate__fadeInLeft' label="Nom/Prénom" variant="filled" style={{ backgroundColor: '#ffffff', borderRadius: '10px' }} />
                                    <TextField required value={email} onChange={(e) => setEmail(e.target.value)} className='animate__animated animate__fadeInLeft' label="Email" variant="filled" style={{ backgroundColor: '#ffffff', borderRadius: '10px' }} />
                                    <TextField required value={phone} onChange={(e) => setPhone(e.target.value)} className='animate__animated animate__fadeInLeft' label="Téléphone" variant="filled" style={{ backgroundColor: '#ffffff', borderRadius: '10px' }} />




                                    <Button type="submit" className='animate__animated animate__fadeInRight' variant="contained" sx={{ backgroundColor: '#609ff2', mt: 3, width: '20%' }}>Envoyer</Button>
                                </form>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}

export default Verifier;
