import { Alert, Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material'
import React from 'react'
import somme from '../assets/formation-audit-énergetique.jpeg'
import pays from '../assets/map-of-france-3141895_960_720.png';
import GroupIcon from '@mui/icons-material/Group';
import InterestsIcon from '@mui/icons-material/Interests';
import BadgeIcon from '@mui/icons-material/Badge';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ConstructionIcon from '@mui/icons-material/Construction';
import { NavLink } from 'react-router-dom'
import { Typewriter } from 'react-simple-typewriter';
import useIsMobile from './useIsMobile';
import emailjs from 'emailjs-com';
function Qui() {
    const [open, setOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('success');
    const isMobile = useIsMobile();
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [workType, setWorkType] = React.useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        // Make fields required
        if (!name || !email || !phone || !postalCode || !workType) {
            setAlertMessage('Veuillez remplir tous les champs.');
            setAlertSeverity('error');
            setOpen(true);
            return;
        }

        const templateParams = {
            name: name,
            email: email,
            phone: phone,
            postalCode: postalCode,
            workType: workType,
        };

        emailjs.send('service_27k6s9b', 'template_kgxrah5', templateParams, "s6Nh9NJ9gJmcA1AL9")
            .then((response) => {
                console.log('Données envoyées avec succès!', response.status, response.text);
                setAlertMessage('Votre message à été envoyé.');
                setAlertSeverity('success');
                setOpen(true);
                // Clear the form
                setName('');
                setEmail('');
                setPhone('');
                setPostalCode('');
                setWorkType('');
            }, (err) => {
                console.error('Erreur lors de l\'envoi des données : ', err);
                setAlertMessage('Erreur lors de l\'envoi du message.');
                setAlertSeverity('error');
                setOpen(true);
            });
    };
    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%', fontSize: '1.5em' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Box sx={{ position: 'relative' }}>
                <Box sx={{ display: { xs: 'none', md: 'block' }, marginBottom: '20px', position: 'absolute', top: -55, left: 0, right: 0, width: '15%', backgroundColor: '#053b63', borderRadius: '0 40px 40px 0', color: 'white', zIndex: 10, padding: '20px', textAlign: 'center' }}>
                    <Typography letterSpacing={"2px"} variant='h6'>Qui sommes-nous ?</Typography>
                </Box>
                <Box sx={{ maxWidth: '100%', margin: 'auto', marginTop: { xs: '50px', md: '170px' } }}>
                    <Grid container alignItems={'center'} spacing={0}>

                        <Grid item xs={12} md={6}>
                            <Box sx={{ padding: '30px', textAlign: 'center', p: 6 }}>
                                <Typography sx={{ color: '#053961', fontWeight: 'bold' }} variant="h4">Votre agence AESE

                                    <span style={{ color: '#e43645', fontWeight: 'bold' }}> <Typewriter
                                        words={['est à votre service depuis 2006']}
                                        typeSpeed={70}
                                        cursorBlinking={true}
                                        cursorColor="#e43645"
                                        cursor={true}
                                        cursorStyle="|"
                                    /></span></Typography>
                                <Typography sx={{ color: '#053961', fontSize: { xs: '18px' }, fontWeight: 'light' }} paragraph variant="h6" style={{ marginTop: "15px" }}>Depuis 2006, Agence AESE participe à la lutte contre la précarité énergétique en tant que professionnel des travaux dans le domaine des énergies renouvelables.</Typography>
                                <Typography sx={{ color: '#053961', fontSize: { xs: '18px' }, fontWeight: 'light' }} paragraph variant="h6" style={{ marginTop: "15px" }}>Notre développement a commencé depuis notre premier bureau administratif en plein coeur de Paris. Au fil des chantiers, nous avons pu étendre notre zone d’intervention sur toute la France et installer un nouveau bureau à Courbevoie.</Typography>
                                <Typography sx={{ color: '#053961', fontSize: { xs: '18px' }, fontWeight: 'light' }} paragraph variant="h6" style={{ marginTop: "15px" }}>Notre groupe est constitué de 3 pôles distincts destinés à réaliser votre projet : Commercial, Production et Administratif.</Typography>
                                <NavLink style={{ textDecoration: 'none' }}><Button sx={{
                                    backgroundColor: "#609ff2",
                                    borderRadius: "5px",
                                    textAlign: "center",
                                    color: "white",
                                    marginTop: "20px",
                                    width: "290px",
                                    fontSize: "14px", /* reduced font size */
                                    padding: "10px 14px", /* reduced padding */
                                    fontFamily: "Roboto, sans-serif",
                                    fontWeight: "bold",
                                    borderStyle: "solid 2px #609ff2",
                                    "&:hover": {
                                        backgroundColor: "#053961", /* Change la couleur de fond lors du survol */
                                        color: "#ffffff"
                                    }
                                }}>Nous contacter au 09 70 26 53 20</Button></NavLink>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box component='img' width='80%' borderRadius={'30% 30% 0 30%'} src={somme} alt='france' />
                        </Grid>

                    </Grid>
                </Box>
                <Box sx={{ maxWidth: '100%', margin: 'auto', marginTop: '50px', marginBottom: '80px' }}>
                    <Grid container alignItems={'center'} spacing={0}>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box component='img' width='80%' height='auto' src={pays} alt='france' />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ padding: '25px', textAlign: 'center', p: 6 }}>
                                <Typography sx={{ color: '#053961', fontWeight: 'bold', letterSpacing: '3px' }} variant="h5">Un déploiement sur tout le territoire nationale</Typography>
                                <Typography sx={{ color: '#e43645', fontWeight: 'bold' }} variant="h5">Grâce à nos entrepôts et nos équipes d’installateurs agréés RGE répartis sur l’ensemble de la France, nous réalisons vos projets dans un délais idéal, avec des équipes disponibles partout.</Typography>
                                <Divider sx={{ marginTop: "10px" }} />
                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: '10px' }}>
                                    <GroupIcon sx={{ color: '#053961', fontSize: '30px' }} />
                                    <Typography sx={{ color: '#053961', fontWeight: 'bold', marginLeft: '15px' }} variant="h6">Un conseiller dédié pour vous éclairer dans votre projet</Typography>
                                </Box>
                                <Divider sx={{ marginTop: "10px" }} />
                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: '10px' }}>
                                    <InterestsIcon sx={{ color: '#053961', fontSize: '30px' }} />
                                    <Typography paragraph sx={{ color: '#053961', fontWeight: 'bold', marginLeft: '15px' }} variant="h6">
                                        Du matériel de qualité répondant aux standards CEE</Typography>
                                </Box>
                                <Divider sx={{ marginTop: "10px" }} />
                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: '10px' }}>
                                    <BadgeIcon sx={{ color: '#053961', fontSize: '35px' }} />
                                    <Typography paragraph sx={{ color: '#053961', fontWeight: 'bold', marginLeft: '15px' }} variant="h6">Un service assistance disponible 7/7</Typography>
                                </Box>
                                <Divider sx={{ marginTop: "10px" }} />
                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: '10px' }}>
                                    <WarehouseIcon sx={{ color: '#053961', fontSize: '30px' }} />
                                    <Typography paragraph sx={{ color: '#053961', fontWeight: 'bold', marginLeft: '15px' }} variant="h6">Des entrepôts dans toute la France</Typography>
                                </Box>
                                <Divider sx={{ marginTop: "10px" }} />
                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: '10px' }}>
                                    <EmojiEventsIcon sx={{ color: '#053961', fontSize: '30px' }} />
                                    <Typography paragraph sx={{ color: '#053961', fontWeight: 'bold', marginLeft: '15px' }} variant="h6">Des installateurs agréés RGE proche de chez vous</Typography>
                                </Box>
                                <Divider sx={{ marginTop: "10px" }} />
                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: '10px' }}>
                                    <ConstructionIcon sx={{ color: '#053961', fontSize: '30px' }} />
                                    <Typography paragraph sx={{ color: '#053961', fontWeight: 'bold', marginLeft: '15px' }} variant="h6">Des installations respectant les exigences du label RGE</Typography>
                                </Box>
                            </Box>
                        </Grid >
                    </Grid >
                </Box >

                <Box className='animate__animated animate__fadeIn'
                    sx={{
                        padding: '50px',
                        backgroundColor: '#053961',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        marginBottom: '50px',
                    }}
                >
                    <Typography variant='h6' sx={{ marginBottom: '10px', color: '#ffffff' }}>Profitez d’un appel gratuit avec un conseiller affilié à votre secteur géographique.</Typography>
                    <form onSubmit={handleSubmit} style={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px' }}>
                        <TextField
                            required
                            value={name}
                            onChange={(event) => setName(event.target.value)} className={`animate__animated ${isMobile ? '' : 'animate__fadeInLeft'}`} label="Nom/Prénom" variant="filled" style={{ backgroundColor: '#ffffff', flex: '1 1 300px', width: '200px', marginRight: '10px', borderRadius: '10px', }} />
                        <TextField
                            required
                            value={email}
                            onChange={(event) => setEmail(event.target.value)} className={`animate__animated ${isMobile ? '' : 'animate__fadeInLeft'}`} label="Email" variant="filled" style={{ backgroundColor: '#ffffff', flex: '1 1 300px', width: '200px', marginRight: '10px', borderRadius: '10px' }} />
                        <TextField
                            required
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)} className={`animate__animated ${isMobile ? '' : 'animate__fadeInLeft'}`} label="Téléphone" variant="filled" style={{ backgroundColor: '#ffffff', flex: '1 1 150px', width: '200px', marginRight: '10px', borderRadius: '10px' }} />
                        <TextField
                            required
                            value={postalCode}
                            onChange={(event) => setPostalCode(event.target.value)} className={`animate__animated ${isMobile ? '' : 'animate__fadeInRight'}`} label="Code postal" variant="filled" style={{ backgroundColor: '#ffffff', flex: '1 1 150px', width: '200px', marginRight: '10px', borderRadius: '10px' }} />
                        <FormControl className={`animate__animated ${isMobile ? '' : 'animate__fadeInRight'}`} variant="filled" style={{ backgroundColor: '#ffffff', flex: '1 1 150px', width: '200px', marginRight: '10px', borderRadius: '10px' }}>
                            <InputLabel color="secondary">Type de travaux</InputLabel>
                            <Select
                                required
                                value={workType}
                                onChange={(event) => setWorkType(event.target.value)} defaultValue="">
                                <MenuItem value={"Panneau Photovoltaïque"}>Panneau Photovoltaïque</MenuItem>
                                <MenuItem value={"Ballon thermodynamique"}>Ballon thermodynamique</MenuItem>
                                <MenuItem value={"PAC Air-eau"}>PAC Air-eau</MenuItem>
                                <MenuItem value={"PAC Air-air"}>PAC Air-air</MenuItem>
                                <MenuItem value={"Rénovation globale"}>Rénovation globale</MenuItem>
                                <MenuItem value={"Autre"}>Autre</MenuItem>
                            </Select>
                        </FormControl>
                        <Button type="submit" className={`animate__animated ${isMobile ? '' : 'animate__fadeInRight'}`} variant="contained" sx={{ backgroundColor: '#609ff2', flex: '1 1 100px', maxWidth: '100px' }}>Envoyer</Button>
                    </form>
                </Box>
            </Box>
        </>
    )
}

export default Qui