import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Question from '../components/Question'
function Faq() {
    return (
        <>
            <header>
                <Header />
            </header>
            <main>
                <section>
                    <Question />
                </section>
            </main>
            <footer>
                <Footer />
            </footer>

        </>
    )
}

export default Faq