import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, Box, IconButton, Drawer, useTheme, useMediaQuery, List, ListItem, ListItemText, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logo from "../assets/logo.png";
import { NavLink, useLocation } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function Header() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileOpen, setMobileOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const location = useLocation();

    const drawer = (
        <div>
            <IconButton onClick={handleDrawerToggle}>
                <CloseIcon />
            </IconButton>
            <List>

                <NavLink style={{ textDecoration: 'none', color: 'black', width: '100%' }} to="/"><ListItem className='animate__animated animate__fadeInDown ' style={{ backgroundColor: location.pathname === '/' ? '#053b63' : 'transparent', color: location.pathname === '/' ? 'white' : 'black' }} button><ListItemText sx={{ fontSize: '20px' }} primary="Accueil" /></ListItem></NavLink>
                <Divider className='animate__animated animate__fadeInDown ' />
                <NavLink style={{ textDecoration: 'none', color: 'black', width: '100%', }} to='/pac-air-eau'><ListItem className='animate__animated animate__fadeInDown ' style={{ backgroundColor: location.pathname === '/pac-air-eau' ? '#053b63' : 'transparent', color: location.pathname === '/pac-air-eau' ? 'white' : 'black' }} button><ListItemText sx={{ fontSize: '20px' }} primary="Pompe à chaleur Air-eau" /></ListItem></NavLink>
                <Divider className='animate__animated animate__fadeInDown ' />
                <NavLink style={{ textDecoration: 'none', color: 'black', width: '100%', }} to='/pac-air-air'><ListItem className='animate__animated animate__fadeInDown  ' style={{ backgroundColor: location.pathname === '/pac-air-air' ? '#053b63' : 'transparent', color: location.pathname === '/pac' ? 'white' : 'black' }} button><ListItemText sx={{ fontSize: '20px' }} primary="Pompe à chaleur Air-Air" /></ListItem></NavLink>
                <Divider className='animate__animated animate__fadeInDown ' />
                <NavLink style={{ textDecoration: 'none', color: 'black', width: '100%', }} to='/ballon-thermodynamique'><ListItem className='animate__animated animate__fadeInDown' style={{ backgroundColor: location.pathname === '/ballon-thermodynamique' ? '#053b63' : 'transparent', color: location.pathname === '/ballon-thermodynamique' ? 'white' : 'black' }} button><ListItemText sx={{ fontSize: '20px' }} primary="Ballon thermodynamique" /></ListItem></NavLink>
                <Divider className='animate__animated animate__fadeInDown ' />
                <NavLink style={{ textDecoration: 'none', color: 'black', width: '100%', }} to='/panneau-photovoltaique'><ListItem className='animate__animated animate__fadeInDown ' style={{ backgroundColor: location.pathname === '/panneau-photovoltaique' ? '#053b63' : 'transparent', color: location.pathname === '/panneau-photovoltaique' ? 'white' : 'black' }} button><ListItemText sx={{ fontSize: '20px' }} primary="Panneau photovoltaique" /></ListItem></NavLink>
                <Divider className='animate__animated animate__fadeInDown ' />
                <NavLink style={{ textDecoration: 'none', color: 'black', width: '100%', }} to='/qui-sommes-nous'><ListItem className='animate__animated animate__fadeInDown ' style={{ backgroundColor: location.pathname === '/qui-sommes-nous' ? '#053b63' : 'transparent', color: location.pathname === '/qui-sommes-nous' ? 'white' : 'black' }} button><ListItemText sx={{ fontSize: '20px' }} primary="Qui sommes-nous ?" /></ListItem></NavLink>
                <Divider className='animate__animated animate__fadeInDown ' />

                <NavLink style={{ textDecoration: 'none', color: 'black', width: '100%', }} to='/contact'><ListItem className='animate__animated animate__fadeInDown  ' style={{ backgroundColor: location.pathname === '/contact' ? '#053b63' : 'transparent', color: location.pathname === '/contact' ? 'white' : 'black' }} button><ListItemText sx={{ fontSize: '20px' }} primary="Contact" /></ListItem></NavLink>
                <Divider className='animate__animated animate__fadeInDown ' />
                <NavLink style={{ textDecoration: 'none', color: 'black', width: '100%', }} to='/faq'><ListItem className='animate__animated animate__fadeInDown ' style={{ backgroundColor: location.pathname === '/faq' ? '#053b63' : 'transparent', color: location.pathname === '/faq' ? 'white' : 'black' }} button><ListItemText sx={{ fontSize: '20px' }} primary="FAQ" /></ListItem></NavLink>
                <Divider className='animate__animated animate__fadeInDown ' />
                <NavLink style={{ textDecoration: "none", color: 'black', width: '100%' }} to='/eligibilite'><ListItem className='animate__animated animate__fadeInDown  ' style={{ backgroundColor: location.pathname === '/eligibilite' ? '#053b63' : 'transparent', color: location.pathname === '/eligibilite' ? 'white' : 'black' }} button><ListItemText sx={{ fontSize: '20px' }} primary="Eligibilité" /></ListItem></NavLink>

            </List>
        </div>
    );

    return (
        <AppBar sx={{ backgroundColor: '#053b63', height: '75px' }} position="fixed">
            <Toolbar>
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                    <Box display="flex" width={"100%"} alignItems="center">
                        <Box component={'img'} src={logo} alt="logo" sx={{ height: '70px' }} />
                        <Typography component={"a"} href='/' sx={{ fontFamily: 'Roboto, sans-serif', "&:hover": { color: '#89cff0', backgroundColor: 'transparent' }, textDecoration: 'none', color: 'white', fontSize: { xs: '13px', md: '18px' } }} variant="h6">
                            Agence Environnementale pour Solutions Énergétiques
                        </Typography>
                    </Box>
                    {isMobile ? (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </IconButton>
                    ) : (
                        <Box display="flex" justifyContent="flex-end" alignItems={'center'} width="100%">

                            <Button sx={{ "&:hover": { color: '#89cff0', backgroundColor: 'transparent' }, fontFamily: 'Roboto, sans-serif', fontSize: '14px' }} aria-controls="mouse-over-popover" aria-haspopup="true" color="inherit" onMouseOver={handleMenu}>
                                Les produits 2023
                            </Button>
                            <Menu
                                sx={{ padding: "40px" }}
                                id="mouse-over-popover"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                padding="40px"
                                MenuListProps={{
                                    'aria-labelledby': 'mouse-over-popover',
                                }}
                                onMouseLeave={handleClose}
                            >
                                <NavLink style={{ textDecoration: 'none', color: 'black' }} to='/pac-air-eau'> <MenuItem sx={{ backgroundColor: location.pathname === '/pac-air-eau' ? '#053b63' : 'transparent', color: location.pathname === '/pac-air-eau' ? 'white' : 'black', "&:hover": { color: 'white', backgroundColor: '#053b63' }, fontFamily: 'Roboto, sans-serif', marginBottom: '15px' }} onClick={handleClose}>(PAC) Pompe à chaleur Air - Eau</MenuItem></NavLink>
                                <NavLink style={{ textDecoration: 'none', color: 'black' }} to='/pac-air-air'><MenuItem sx={{ backgroundColor: location.pathname === '/pac-air-air' ? '#053b63' : 'transparent', color: location.pathname === '/pac-air-air' ? 'white' : 'black', "&:hover": { color: 'white', backgroundColor: '#053b63' }, fontFamily: 'Roboto, sans-serif', marginBottom: '15px' }} onClick={handleClose}>(PAC) Pompe à chaleur Air - Air</MenuItem></NavLink>
                                <NavLink style={{ textDecoration: 'none', color: 'black' }} to='/ballon-thermodynamique'><MenuItem sx={{ backgroundColor: location.pathname === '/ballon-thermodynamique' ? '#053b63' : 'transparent', color: location.pathname === '/ballon-thermodynamique' ? 'white' : 'black', "&:hover": { color: 'white', backgroundColor: '#053b63' }, fontFamily: 'Roboto, sans-serif', marginBottom: '15px' }} onClick={handleClose}>(BTD) Ballon thermodynamique</MenuItem></NavLink>
                                <NavLink style={{ textDecoration: 'none', color: 'black' }} to='/panneau-photovoltaique'><MenuItem sx={{ backgroundColor: location.pathname === '/panneau-photovoltaique' ? '#053b63' : 'transparent', color: location.pathname === '/panneau-photovoltaique' ? 'white' : 'black', "&:hover": { color: 'white', backgroundColor: '#053b63' }, fontFamily: 'Roboto, sans-serif' }} onClick={handleClose}>(PV) Panneaux photovoltaïques</MenuItem></NavLink>
                            </Menu>
                            <NavLink style={{ textDecoration: 'none' }} to='/qui-sommes-nous'><Button sx={{ "&:hover": { color: '#89cff0', backgroundColor: 'transparent' }, fontFamily: 'Roboto, sans-serif', fontSize: '13px', color: location.pathname === '/qui-sommes-nous' ? '#89cff0' : 'white' }}>Qui sommes nous ?</Button></NavLink>
                            <NavLink style={{ textDecoration: 'none' }} to='/contact'><Button sx={{ "&:hover": { color: '#89cff0', backgroundColor: 'transparent' }, fontFamily: 'Roboto, sans-serif', fontSize: '13px', color: location.pathname === '/contact' ? '#89cff0' : 'white' }}>Nous contacter</Button></NavLink>
                            <NavLink style={{ textDecoration: 'none' }} to='/faq'><Button sx={{ "&:hover": { color: '#89cff0', backgroundColor: 'transparent' }, fontFamily: 'Roboto, sans-serif', fontSize: '13px', color: location.pathname === '/faq' ? '#89cff0' : 'white' }}>FAQ</Button></NavLink>
                            <NavLink style={{ textDecoration: 'none' }} to='/eligibilite'> <Button sx={{ textTransform: "inherit", backgroundColor: '#89cff0', borderRadius: '50px', padding: '25px', whiteSpace: 'nowrap', height: '50px', "&:hover": { color: "white", backgroundColor: "#609ff2" }, fontFamily: 'Roboto, sans-serif', fontSize: '14px', color: location.pathname === '/eligibilite' ? '#053b63' : 'white' }}>Suis-je éligible aux aides ? <ArrowForwardIcon sx={{ marginLeft: '10px' }} /> </Button></NavLink>
                        </Box>
                    )}
                    <Drawer

                        anchor="top"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                    >
                        {drawer}
                    </Drawer>
                </Box>
            </Toolbar>
        </AppBar >
    )
}

export default Header;
