import React, { useEffect, useRef } from 'react'
import { Navigation, Autoplay, EffectFade } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react';
import anime from 'animejs';
import renovation from '../assets/aide-renovation-energetique-action-logement-e297a23fed819fda306a70d59dfce7df.webp';
import panneau from '../assets/Auge-renovables.webp'
import clim from '../assets/climatisation.webp';
import pac from '../assets/pacHybride.png';
import ballon from '../assets/ballon.webp';
import '../style/components/slider1.scss'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-fade'
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'
// Import your CSS here, move your CSS code to this file
import emailjs from 'emailjs-com';
import useIsMobile from './useIsMobile';

function Slider1() {
    const swiperRef = useRef(null);
    const [open, setOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('success');
    const isMobile = useIsMobile();
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [workType, setWorkType] = React.useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        // Make fields required
        if (!name || !email || !phone || !postalCode || !workType) {
            setAlertMessage('Veuillez remplir tous les champs.');
            setAlertSeverity('error');
            setOpen(true);
            return;
        }

        const templateParams = {
            name: name,
            email: email,
            phone: phone,
            postalCode: postalCode,
            workType: workType,
        };

        emailjs.send('service_27k6s9b', 'template_kgxrah5', templateParams, "s6Nh9NJ9gJmcA1AL9")
            .then((response) => {
                console.log('Données envoyées avec succès!', response.status, response.text);
                setAlertMessage('Votre message à été envoyé.');
                setAlertSeverity('success');
                setOpen(true);
                // Clear the form
                setName('');
                setEmail('');
                setPhone('');
                setPostalCode('');
                setWorkType('');
            }, (err) => {
                console.error('Erreur lors de l\'envoi des données : ', err);
                setAlertMessage('Erreur lors de l\'envoi du message.');
                setAlertSeverity('error');
                setOpen(true);
            });
    };
    useEffect(() => {
        if (swiperRef.current && swiperRef.current.slides) {
            const currentSlide = swiperRef.current.slides[0];
            const background = currentSlide.querySelector('.background');
            anime({
                targets: background,
                scale: [1, 1.2],
                duration: 5000,
                easing: 'easeInOutSine',
                loop: false,
            });
        }
    }, []);
    const animateSlide = (slide) => {
        const background = slide.querySelector('.background');
        anime({
            targets: background,
            scale: [1, 1.2],
            duration: 5000,
            easing: 'easeInOutSine',
            loop: false,
        });
    }
    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%', fontSize: '1.5em' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Swiper
                className='swiper'
                modules={[Navigation, Autoplay, EffectFade]}
                loop={true}
                speed={1000}
                navigation
                pagination={{ clickable: true }}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                }}
                effect='fade'
                ref={swiperRef}
                onSwiper={(swiper) => animateSlide(swiper.slides[0])}
                onSlideChange={(swiper) => animateSlide(swiper.slides[swiper.activeIndex])}
            >
                <SwiperSlide >
                    <div className='background' style={{ backgroundImage: `url(${panneau})`, backgroundPosition: 'top' }} />
                    <div className='content-wrapper'>
                        <div className='title-service animate__animated animate__fadeInUp'>
                            <h3>Panneau Photovoltaïque</h3>
                        </div>
                        <NavLink to="/panneau-photovoltaique" className='animate__animated animate__fadeInUp animate__delay-1s' >
                            En savoir plus
                        </NavLink>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="background" style={{ backgroundImage: `url(${ballon})`, backgroundPosition: 'center', }} />
                    <div className='content-wrapper'>
                        <div className='title-service animate__animated animate__fadeInUp'>
                            <h3>Ballon Thermodynamique</h3>
                        </div>
                        <NavLink to="/ballon-thermodynamique" className='animate__animated animate__fadeInUp animate__delay-1s'>
                            En savoir plus
                        </NavLink>
                    </div>
                </SwiperSlide>

                <SwiperSlide >
                    <div className="background" style={{ backgroundImage: `url(${pac})`, backgroundPosition: 'top' }} />
                    <div className='content-wrapper'>
                        <div className='title-service animate__animated animate__fadeInUp'>
                            <h3>Pompe à chaleur Air-eau</h3>
                        </div>
                        <NavLink to='/pac-air-eau' className='animate__animated animate__fadeInUp animate__delay-1s'>
                            En savoir plus
                        </NavLink>
                    </div>
                </SwiperSlide>

                <SwiperSlide >
                    <div className='background' style={{ backgroundImage: `url(${clim})` }} />
                    <div className='content-wrapper'>
                        <div className='title-service animate__animated animate__fadeInUp'>
                            <h3>Pompe à chaleur Air-Air</h3>
                        </div>
                        <NavLink to="/pac-air-air" className='animate__animated animate__fadeInUp animate__delay-1s' >
                            En savoir plus
                        </NavLink>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div className='background' style={{ backgroundImage: `url(${renovation})`, }} />
                    <div className='content-wrapper'>
                        <div className='title-service animate__animated animate__fadeInUp'>
                            <h3>Rénovation globale</h3>
                        </div>

                    </div>
                </SwiperSlide>



                {/* Add more SwiperSlides as necessary... */}
            </Swiper>
            <Box className='animate__animated animate__fadeIn'
                sx={{
                    padding: '50px',
                    backgroundColor: '#053961',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <Typography variant='h6' sx={{ marginBottom: '10px', color: '#ffffff' }}>Profitez d’un appel gratuit avec un conseiller affilié à votre secteur géographique.
                </Typography>
                <form onSubmit={handleSubmit} style={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px' }}>
                    <TextField
                        required
                        value={name}
                        onChange={(event) => setName(event.target.value)} className={`animate__animated ${isMobile ? '' : 'animate__fadeInLeft'}`} label="Nom/Prénom" variant="filled" style={{ backgroundColor: '#ffffff', flex: '1 1 300px', width: '200px', marginRight: '10px', borderRadius: '10px', }} />
                    <TextField
                        required
                        value={email}
                        onChange={(event) => setEmail(event.target.value)} className={`animate__animated ${isMobile ? '' : 'animate__fadeInLeft'}`} label="Email" variant="filled" style={{ backgroundColor: '#ffffff', flex: '1 1 300px', width: '200px', marginRight: '10px', borderRadius: '10px' }} />
                    <TextField
                        required
                        value={phone}
                        onChange={(event) => setPhone(event.target.value)} className={`animate__animated ${isMobile ? '' : 'animate__fadeInLeft'}`} label="Téléphone" variant="filled" style={{ backgroundColor: '#ffffff', flex: '1 1 150px', width: '200px', marginRight: '10px', borderRadius: '10px' }} />
                    <TextField
                        required
                        value={postalCode}
                        onChange={(event) => setPostalCode(event.target.value)} className={`animate__animated ${isMobile ? '' : 'animate__fadeInRight'}`} label="Code postal" variant="filled" style={{ backgroundColor: '#ffffff', flex: '1 1 150px', width: '200px', marginRight: '10px', borderRadius: '10px' }} />
                    <FormControl className={`animate__animated ${isMobile ? '' : 'animate__fadeInRight'}`} variant="filled" style={{ backgroundColor: '#ffffff', flex: '1 1 150px', width: '200px', marginRight: '10px', borderRadius: '10px' }}>
                        <InputLabel color="secondary">Type de travaux</InputLabel>
                        <Select
                            required
                            value={workType}
                            onChange={(event) => setWorkType(event.target.value)} defaultValue="">
                            <MenuItem value={"Panneau Photovoltaïque"}>Panneau Photovoltaïque</MenuItem>
                            <MenuItem value={"Ballon thermodynamique"}>Ballon thermodynamique</MenuItem>
                            <MenuItem value={"PAC Air-eau"}>PAC Air-eau</MenuItem>
                            <MenuItem value={"PAC Air-air"}>PAC Air-air</MenuItem>
                            <MenuItem value={"Rénovation globale"}>Rénovation globale</MenuItem>
                            <MenuItem value={"Autre"}>Autre</MenuItem>
                        </Select>
                    </FormControl>
                    <Button type="submit" className={`animate__animated ${isMobile ? '' : 'animate__fadeInRight'}`} variant="contained" sx={{ backgroundColor: '#609ff2', flex: '1 1 100px', maxWidth: '100px' }}>Envoyer</Button>
                </form>
            </Box>

        </>
    )
}

export default Slider1
