import { Box, Typography, Grid } from '@mui/material';
import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import EuroIcon from '@mui/icons-material/Euro';
import HandshakeIcon from '@mui/icons-material/Handshake';

function Mission() {
    return (
        <>
            <Box sx={{
                padding: '50px',
            }}>
                < Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography align='center' sx={{ color: '#cbe5ae', fontFamily: 'Roboto, sans-serif' }} variant='h3' mb={2}>Notre Mission</Typography>
                    <Typography align='center' sx={{ color: '#053b63', fontWeight: 'bold', fontStyle: 'italic' }} mb={2} >Simplifier votre transition énergétique par le biais de notre programme clé en main.</Typography>
                </Box >

                <Grid container mt={3} spacing={3}>
                    <Grid item xs={12} sm={6} md={3} >
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <HandshakeIcon sx={{ fontSize: '80px', color: '#a4d471' }} />
                            <Typography align='center' sx={{ display: { xs: 'none', md: 'block' }, color: '#053b63', fontWeight: 'bold' }} mb={2} variant='h5'>Accompagnement de A à Z</Typography>
                            <Typography align='center' sx={{ display: { xs: 'block', md: 'none' }, color: '#053b63', fontWeight: 'bold' }} mb={2} variant='h5'>Accompagnement <br /> De A à Z</Typography>
                            <Typography sx={{ fontFamily: 'Roboto, sans-serif', color: '#053b63', fontWeight: 'light' }} align='center'>Pour constituer votre dossier d'adhésion à la transition énergétique. En vue d'une d’une installation de pompe à chaleur par exemple.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <EuroIcon sx={{ fontSize: '80px', color: '#a4d471' }} />
                            <Typography align='center' sx={{ color: '#053b63', fontWeight: 'bold' }} mb={2} variant='h5'>Dossier administratif</Typography>
                            <Typography sx={{ fontFamily: 'Roboto, sans-serif', color: '#053b63', fontWeight: 'light' }} align='center'>Prise en charge à 100%, bénéficiez du programme 2023. Incluant les démarches accélérées aux aides d'État, notamment Maprimerénov.

                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <SelfImprovementIcon sx={{ fontSize: '80px', color: '#a4d471' }} />
                            <Typography align='center' sx={{ color: '#053b63', fontWeight: 'bold' }} mb={2} variant='h5'>Pas d'avance de frais</Typography>
                            <Typography sx={{ fontFamily: 'Roboto, sans-serif', color: '#053b63', fontWeight: 'light' }} align='center'>Solutions de financement personnalisées en cas de reste à charge pour votre projet. Ni apport, ni acompte le jour de l'installation

                                Ni apport, ni acompte le jour de l'installation.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <CheckCircleOutlineIcon sx={{ fontSize: '80px', color: '#a4d471' }} />
                            <Typography align='center' sx={{ color: '#053b63', fontWeight: 'bold' }} mb={2} variant='h5'>Installation, mise en service</Typography>
                            <Typography sx={{ fontFamily: 'Roboto, sans-serif', color: '#053b63', fontWeight: 'light' }} align='center'>Affiliation d'une équipe locale interne du groupe A.E.S.E ; un plombier, un chauffagiste et un électricien, pour une installation rapide et qualitative.</Typography>
                        </Box>
                    </Grid>
                </Grid >
            </Box >
        </>
    )
}

export default Mission;
